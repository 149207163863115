/**
* Contains styles pertaining to the about us page
*/
.front_door_container {
  .info-container {
    &.dark {
      background-color: $dark-grid-grey;
    }
    width: 100%;
    p {
      font-size: rem(14);
    }
    a {
      font-weight: $font_weight_medium;
    }

    .inside-info {
      @include grid-row();
      padding: 30px;

      .info-left {
        @include grid(12);
        border-bottom: 1px solid $some-grey-border;
        padding-bottom: 30px;
        padding-left: 0px;

        h2 {
          margin-top: 0px;
        }

        p {
          line-height: 1.5;
          margin-bottom: 8px;
          padding-top: 13px;

          &:last-child {
            margin-bottom: 0px;
          }
        }
      }

      .info-right {
        @include grid(12);
        padding-top: 30px;
        padding-left: 0px;

        h2 {
          margin-top: 0px;
        }

        p {
          line-height: 1.5;
          margin-bottom: 8px;
          padding-top: 13px;

          &:last-child {
            margin-bottom: 0px;
          }
        }
      }
    }
  }

  .story-container {
    background-color: $grid-grey;
    width: 100%;
    p {
      font-size: rem(14);
    }
    a {
      font-weight: $font_weight_medium;
    }

    .story {
      padding: 30px;
      margin-left: auto;
      margin-right: auto;

      h2 {
        margin: 0px auto 20px;
      }

      p {
        line-height: 1.5;
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }

  @media #{$medium-up} {
    .info-container {
      .inside-info {
        padding: 40px;

        .info-left {
          @include grid(6);
          border-bottom: none;
          border-right: 1px solid $some-grey-border;
          padding-right: 40px;
          padding-left: 0px;
        }

        .info-right {
          @include grid(6);
          padding-left: 40px;
          padding-top: 0px;
        }
      }
    }

    .story-container {
      .story {
        padding: 40px;
      }
    }
  }

  @media #{$large-up} {
    .info-container {
      .inside-info {
        padding: 50px 100px;

        .info-left {
          padding-right: 90px;
        }

        .info-right {
          padding-left: 95px;
        }
      }
    }

    .story-container {
      .story {
        width: 1024px;
        padding: 50px 40px;
      }
    }
  }
}
