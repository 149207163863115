.faq-overview {
  padding: 15px;
  width: 100%;

  .faq-overview__headline {
    text-align: center;
    margin: 37px 0 30px 0;
  }

  .faq-overview__tile-area {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    margin-bottom: 0;
    max-width: 1146px;
    margin-bottom: 32px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-flow: row wrap;
  }

  .faq-overview__tile {
    margin-top: 0;
    margin-bottom: 30px;
    max-width: 382px;
    position: relative;

    padding-left: 15px;
    padding-right: 15px;
    display: block;
    background: none;
    border: none;

    flex: 0 0 100%;
    width: 100%;
    text-align: center;

    @media #{$large-up} {
      flex: 0 0 33.3%;
      width: 33%;
      text-align: left;
      &:nth-child(3n) {
        page-break-after: always;
        break-after: always;
      }
    }
  }

  .faq-overview__tile-content {
    position: relative;
    width: 100%;
    max-width: 352px;
    padding: 30px;
    height: 180px;
    background: white;
    border: 1px solid $some-grey-border;

    display: flex;
    justify-content: center;
    align-items: center;
    @media #{$large-up} {
      height: 305px;
      padding: 24px 36px;
      display: block;
      transition: box-shadow 350ms;
      &:hover {
        box-shadow: inset 0 0 0 4px $grid-grey;
      }
    }

    a.overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      @media #{$medium-up} {
        display: none;
      }
    }

    header {
      h2 {
        @media #{$large-up} {
          margin-right: 50px;
        }
      }
    }
    hr.line-under-questions {
      display: none;
    }
    hr.line-under-title {
      display: none;
      @media #{$large-up} {
        display: block;
        margin: 16px 0;
        border-bottom: none;
      }
    }
    img {
      width: 62px;
      margin-bottom: 20px;
      @media #{$large-up} {
        float: right;
        //height: auto;
        margin: 0;
        width: auto;
        height: 36px;
      }
    }

    .faq-overview__questions-area {
      display: none;

      @media #{$large-up} {
        display: block;
        height: 110px;
      }

      &,
      .question {
        font-size: rem(14);
        font-weight: bold;
        color: $shale-grey;
        &:hover {
          color: $href_hover_color;
        }
      }

      hr.line-under-questions {
        display: none;
      }

      li {
        margin-bottom: 12px;
      }
    }

    .cta {
      display: none;
      a {
        &:after {
          content: ' ';
          display: inline-block;
          position: relative;
          background-image: url(~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/icons/arrow-bullets-green.svg);
          background-position: center center;
          height: 11px;
          width: 8px;
          margin-left: 6px;
        }
        &:hover:after {
          background-image: url(~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/icons/arrow-bullets-green-hover.svg);
        }
      }
      @media #{$large-up} {
        display: block;
        position: absolute;
        bottom: 16px;
        right: 36px;
        font-size: 14px;
        margin-bottom: 0;
      }
    }
  }
}

%faq-mobile-base {
  margin-left: auto;
  margin-right: auto;
  padding-left: 30px;
  padding-right: 30px;
  @media #{$medium-up} {
    width: 80%;
  }
  @media #{$large-up} {
    width: 100%;
    max-width: 1146px;
    padding: 0;
  }
}

.faq-nav {
  @extend %faq-mobile-base;

  @media #{$large-up} {
    width: 100%;
    max-width: 1146px;
    padding-left: auto;
    padding-right: auto;
  }
  .faq-nav__content {
    margin-top: 36px;
    @media #{$large-up} {
      float: left;
      margin-left: auto;
      width: 30%;
      max-width: 388px;
      margin-top: 43px;
      padding: 0 10% 15px 15px;
    }
  }

  .faq-nav__dropdown {
    margin-bottom: 36px;

    @media #{$large-up} {
      display: none;
    }

    select {
      border-radius: 4px;
      height: 44px;
      padding-left: 16px;
      background-color: transparent;
      background-position-x: calc(100% - 9px);
      background-image: url(~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/icons/arrow-bullets-open-grey.svg);
    }
  }

  .faq-nav__list {
    display: none;
    @media #{$large-up} {
      display: block;
    }
    h3 {
      font-weight: bold;
      margin: 8px 0;

      &,
      & a {
        color: $dark-grey;
      }
    }
    hr {
      margin: 8px 0;
    }
    ul {
      margin: 25px 0;
    }
    li {
      font-size: rem(14);
      margin: 12px 0;
    }
    a {
      color: $shale-grey;
    }
    a.selected {
      color: inherit;
    }
    a:hover {
      color: $href_hover_color;
    }
  }
}

.faq-content {
  @extend %faq-mobile-base;
  @media #{$large-up} {
    max-width: 1146px;
    margin-top: 15px;
  }
  h1 {
    margin: 1em 0;
  }

  h2,
  h3,
  h4,
  ul,
  ol {
    margin-bottom: 1.5em;
  }

  h3,
  h4 {
    font-weight: bold;
  }

  ul:not(.faq-content__index) {
    li {
      list-style-type: disc;
      margin: 6px 0 6px 1.2em;
    }
  }
  ol {
    li {
      list-style-type: decimal;
      margin: 6px 0 6px 1.2em;
    }
  }

  .faq-content__interior {
    @media #{$large-up} {
      padding: 0 15px;
      width: 70%;
      max-width: 776px;
      margin-left: auto;
      float: left;
    }
  }

  .faq-content__headline {
    display: none;
    @media #{$large-up} {
      display: block;
    }
  }
  .faq-content__qa-pair {
    margin: 24px 0 0;
    padding-bottom: 36px;
  }

  .faq-content__return-top {
    font-size: rem(14);
    line-height: 19px;
    margin: 1.5em 0;
    a:before {
      content: url(~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/icons/arrow-circle-up-green.svg);
      display: inline-block;
      width: 17px;
      height: 19px;
      margin-right: 6px;
      vertical-align: top;
    }
  }
  .faq-content__index {
    list-style: none;
    margin-bottom: 32px;
    li {
      margin-bottom: 8px;
    }
  }
  .faq-content__index + hr {
    margin: 64px 0 32px;
  }

  .faq-content__index-item {
    margin-bottom: 8px;
  }
}

.faq-contact-info {
  text-align: center;
  max-width: 1146px;
  margin: auto;

  margin-bottom: 112px;

  h1,
  h2 {
    margin: 24px 0;
  }
  hr {
    margin: 30px 15px;
  }

  .faq-contact-info__block-area {
    display: flex;

    flex-direction: column;
    @media #{$medium-up} {
      justify-content: center;
      flex-direction: row;
      margin: 0;
    }
  }

  .faq-contact-info__block {
    display: block;
    line-height: 31px;
    text-align: center;
    font-size: rem(16);

    @media #{$medium-up} {
      font-size: rem(20);
      text-align: left;
    }

    img {
      display: block;
      margin: 16px auto;
      width: 62px;

      &.live-chat-icon {
        width: 76px;
      }

      @media #{$medium-up} {
        display: inline;
        vertical-align: top;
        width: 46px;
        min-height: 31px;
        margin: 0 20px 0 0;

        &.live-chat-icon {
          width: 46px;
        }
      }
    }

    & + .faq-contact-info__block {
      margin-top: 20px;
      @media #{$medium-up} {
        margin-top: 0;
        margin-left: 100px;
      }
    }
  }
}

.faq-contact-info--left-aligned {
  @extend .faq-contact-info;
  @media #{$large-up} {
    width: 100%;
    max-width: 1146px;
    text-align: left;
    clear: both;
    > *,
    > hr,
    > .faq-contact-info__block-area {
      margin-left: 30%;
      padding-left: 15px;
    }
    hr {
      margin-left: calc(30% + 15px);
    }
    .faq-contact-info__block-area {
      justify-content: flex-start;
    }
  }
}
