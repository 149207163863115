@import 'shared/legacy/customer_support/_mobile';
@import 'istock/customer_support/_mobile';
@import 'istock/customer_support/_email_section';
@import 'istock/customer_support/_how_to_reach_us';
@import 'istock/customer_support/_faq';

section.main_body {
  background-color: #f4f6f6;
}

#live_chat_request_container {
  &.disabled,
  .disabled {
    font-style: normal;
  }
}

body.customer_support_container {
  .content_wrapper {
    color: $dark-grey;

    .customer_support {
      padding-bottom: 20px;
      max-width: 1076px;

      h2 {
        margin: 48px;
        font-size: 32px;
        text-align: center;
      }

      h8.photographer {
        display: none;
      }

      div.content_creators {
        background-color: white;
        margin-bottom: 20px;
        padding: 20px 24px 32px;

        p {
          margin-bottom: 0;
        }
      }

      div.custom_notice {
        background-color: $school-bus-yellow;

        strong {
          color: $error-red;
          font-weight: $font_weight_medium;
        }
      }

      .extend_faq {
        font-weight: normal;
        font-size: rem(14);
        color: #0c0d0d;

        div {
          padding: 0 32px;

          a {
            color: #02a388;
          }
        }
      }

      .divider {
        margin-top: 34px;
      }

      @media #{$large-up} {
        .extend_faq {
          font-size: rem(16);

          div {
            padding: 0 23px;
          }
        }

        .divider {
          display: none;
        }
      }

      a.anchor {
        padding-top: 80px;
        margin-top: -80px;
        display: block;

        @media (width <= 450px) {
          padding-top: 130px;
          margin-top: -130px;
        }
      }
    }
  }
}
