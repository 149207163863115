@import 'unisporkal-styles/stylesheets/unisporkal_engine/istock/_config';

.react-search-bar {
  z-index: 99;
  position: relative;
  margin: auto;
}

.ftlp-search-bar {
  .react-search-bar {
    @media #{$istock-medium-up} {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }

    @media #{$istock-large-up} {
      top: 16px;
      width: 95%;
    }
  }

  // This adjusts the top CMS content banner to match the home page banner proportions with overlapping search bar
  #promo-code
    .promo-code-hero-container
    .promo-code-container
    .plans-and-pricing-cta {
    @media #{$istock-medium-up} {
      margin-top: 141px;
    }
  }
}
