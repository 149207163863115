.landing-tiles {
  position: relative;
}

$tile-height-mobile: 240px;
$tile-height-tablet: 300px;

.featured-tiles {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  max-width: 1146px;
  width: 100%;

  @media #{$mobile} {
    flex-direction: column;
  }

  .callout {
    background-color: rgba(12, 13, 13, 0.7);
    border-radius: 4px;
    bottom: 10px;
    color: #cdd3cf;
    font-size: rem(11);
    padding: 2px 11px;
    position: absolute;
    right: 10px;
  }

  .description-container {
    padding-right: 80px;
    position: absolute;
    left: 43px;
    top: 60px;
    @media #{$medium-up} {
      left: 20px;
      top: 20px;
    }

    @media #{$large-up} {
      left: 43px;
      top: 60px;
    }
  }

  .feature-tile {
    background-color: white;
    border: 1px solid $some-grey-border;
    flex-basis: 33%;
    flex-grow: 1;
    margin: 20px 6px;
    min-height: $tile-height-tablet;
    position: relative;
    -webkit-font-smoothing: antialiased;
    -font-smoothing: antialiased;
    @media #{$medium-up} {
      min-height: $tile-height-tablet;
    }
    @media #{$mobile} {
      flex-basis: auto;
    }
  }

  .feature-tile.stretch {
    flex-grow: 10;
  }

  h4 {
    padding: 3px 20px;
    text-align: left;
  }

  h4.heading {
    font-weight: 500;
    margin-top: 17px;
  }

  h4.copy {
    font-size: 0.9rem;
    margin-bottom: 10px;
  }

  .img-zoom:hover .image-container img {
    opacity: 0.65;
    transform: scale(1.03);
  }

  .image-container {
    align-items: center;
    background-color: #000;
    display: flex;
    height: 100%;
    overflow: hidden;
    position: absolute;
    text-transform: uppercase;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    img {
      opacity: 0.6;
      vertical-align: middle;
      min-height: 100%;
    }
  }

  img {
    width: 100%;
  }

  .photo-information {
    color: white;
    font-size: rem(16);
    margin-top: 20px;

    @media #{$medium-up} {
      font-size: rem(16);
    }
    @media #{$large-up} {
      font-size: rem(20);
    }

    .clarifying-info {
      font-size: rem(11);

      @media #{$medium-up} {
        font-size: rem(11);
      }
      @media #{$large-up} {
        font-size: rem(15);
      }
    }
  }

  .promo-banner & {
    padding-left: 10px;
    padding-right: 10px;
  }

  .tile-title {
    color: white;
    font-size: rem(20);
    font-weight: 500;
    line-height: 1.3;

    @media #{$medium-up} {
      font-size: rem(30);
    }

    @media #{$large-up} {
      font-size: rem(36);
    }
  }

  .two-col {
    &.feature-tile {
      min-height: $tile-height-mobile;
    }
    .photo-information {
      @media #{$medium-up} {
        font-size: rem(18);
      }
      .clarifying-info {
        @media #{$medium-up} {
          font-size: rem(13);
        }
      }
    }

    .description-container {
      left: 20px;
      top: 20px;
      @media #{$medium-up} {
        left: 43px;
        top: 60px;
      }
    }
  }
}

.fancy-grid {
  margin-left: 0.6rem;
  margin-right: 0.6rem;
  max-width: 1280px;
  @media only screen and (min-width: 1292px) {
    margin-left: auto;
    margin-right: auto;
  }
  @media #{$tablet} {
    margin-left: 0;
    margin-right: 0;
  }
  section.recent-searches + &,
  .search-box-placeholder + & {
    padding-top: 1.5em;
  }
  &:nth-of-type(2) {
    margin-top: 2rem;
  }
  &:last-of-type {
    margin-bottom: 4rem;
  }

  div.clipper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -0.4rem;
    margin-right: -0.4rem;
    overflow: hidden;
    @media #{$tablet} {
      margin-left: 0.2rem;
      margin-right: 0.2rem;
    }
    @media #{$mobile} {
      margin-left: 0;
      margin-right: 0;
    }

    > article {
      flex-grow: 1;
      margin: 0.2rem;
      position: relative;
      @media #{$tablet} {
        min-width: 260px;
      }
      @media #{$mobile} {
        min-width: 180px;
      }
      @media only screen and (min-width: 1024px) {
        min-width: 0;
        margin: 0.4rem;
      }

      > a {
        background-position: center center;
        background-size: cover;
        box-sizing: border-box;
        display: none;
        flex: 1;

        &:hover {
          filter: brightness(0.7);
        }

        &.active {
          display: block;
        }
      }
      > div {
        display: none;
        position: absolute;
        right: 10px;
        top: 10px;
        a {
          color: white;
          text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.75);
          &:hover {
            color: $green;
          }
        }
      }
      &:hover {
        > div {
          display: block;
        }
      }
    }
  }

  figure {
    height: 14rem;
    position: relative;
    transition-property: height;
    transition-duration: 1s;
    transition-timing-function: ease-in-out;

    @media #{$tablet} {
      height: 14rem;
    }

    img {
      display: none;
      max-width: 100%;
    }

    figcaption {
      background: linear-gradient(
        -180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(1, 1, 1, 0.7) 70%
      );
      bottom: 0;
      box-sizing: border-box;
      color: white;
      font-size: rem(16);
      letter-spacing: 0.01em;
      line-height: 1.5rem;
      padding: 1rem;
      position: absolute;
      width: 100%;
    }
    .category-tag {
      color: #ccc;
      font-size: rem(11);
      font-weight: normal;
      letter-spacing: 0.02em;
    }
  }
}

// This is targeting reverse-image-search and reverse-video-search pages
// These pages are maintain in the Autonomy CMS
.reverse-asset-search-cms-page {
  float: right;

  @media screen and (max-width: 540px) {
    float: none;
  }
}
