//TODO: Move this to istock/variables
$faq-highlight-color: lighten($some-grey-border, 6%);

.qa-section {
  h2 {
    margin: 50px auto 20px;
    text-align: center;
  }

  .question-answer-block {
    border-top: 1px solid $some-grey-border;
    border-bottom: 1px solid white;
    cursor: pointer;
    margin-bottom: 25px;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    width: 90%;

    h3,
    div {
      transition: background-color 0.2s ease-in;
      background-color: transparent;
      border: none;
    }

    h3 {
      border-bottom: 1px solid $some-grey-border;
      border-top: 1px solid white;
      margin-top: 0;
      padding: 23px 23px 23px 23px;

      &.ui-state-active {
        background-color: $faq-highlight-color;
        border: none;
      }
    }

    div {
      padding: 0 45px 23px 23px;

      &.ui-accordion-content-active {
        background-color: $faq-highlight-color;
      }

      p {
        text-align: left;
        width: 95%;
        &:last-child {
          margin-bottom: 0;
        }
      }

      a {
        color: $green;

        &:hover {
          color: darken($green, 5%);
        }
      }
    }

    .ui-state-focus {
      outline: none;
    }

    .ui-accordion-header-icon {
      background-image: url('~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/icons/arrow-bullets-open.svg');
      background-repeat: no-repeat;
      background-size: contain;
      float: right;
      height: 20px;
      left: initial;
      right: 20px;
      transition: transform $fast-duration ease-in-out;
      width: 17px;

      &.selectedIcon {
        @include transform(rotate(-180deg));
      }
    }
  }

  @media #{$large-up} {
    h2 {
      margin-bottom: 20px;
    }

    .question-answer-block {
      margin-right: auto;
      margin-left: auto;
      max-width: 1146px;
      h3 {
        font-size: rem(18);
        padding: 20px 30px 20px 30px;
      }

      div {
        padding: 0 45px 20px 30px;

        p {
          font-size: rem(14);
        }
      }
    }
  }
}

.more-questions {
  text-align: center;
}
