.tooltip {
  @extend %help-icon;
  font-size: 1rem;
  color: $button-color;
  cursor: pointer;
  background: none;
  margin: 0 0 0 5px;
  display: none;
  position: relative;

  &:hover {
    color: darken($button-color, 20%);
  }

  &:before {
    vertical-align: text-bottom;
  }

  display: inline-block;

  .tooltip_contents {
    max-width: 34em;
    box-shadow: rgba(0, 0, 0, 0.2) 0 0 0.2em;
    border: #ccc 1px solid;
    padding: 1.5rem;
    right: -4rem;
  }

  ul {
    list-style: disc;
    padding: 1.5em;
    li {
      margin-bottom: 0.7em;
      width: 100%;
      display: list-item;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
