@import './_external';

$some-grey-border: #ccd3d3;
$faq-highlight-color: #fff;
$faq-headline-color: #555;
$faq-headline-color-hover: #212121;

.video-editor-faq,
.music-landing-faq {
  width: 100%;
  margin: auto;
  background-color: white;
  overflow: auto;

  #plans-pricing-faq {
    @include clearfix;
    @include grid-row();

    padding: 30px 30px 0;

    h2 {
      font-size: rem(16);
      font-weight: normal;
      margin: 0 auto 15px;
      width: 85%;
      text-align: center;
    }

    h4 {
      margin-left: 2%;
      margin-right: 2%;
    }

    .faq-holder {
      @include grid-column(12);
    }

    .question-answer-block {
      border-top: 1px solid $some-grey-border;
      border-bottom: 1px solid white;
      margin-bottom: 25px;
      text-align: left;
      font-size: rem(12);
      line-height: 1.4;

      // hide answers until jquery-ui takes over
      div {
        display: none;
      }

      &.ui-accordion {
        display: block;
      }

      h3,
      div {
        border-radius: 0;
        transition: background-color 0.2s ease-in;
        background-color: transparent;
        border: none;
        outline: none;
      }

      h3 {
        position: relative;
        border-bottom: 1px solid $some-grey-border;
        border-top: 1px solid white;
        font-size: rem(14);
        margin-top: 0;
        padding: 15px 45px 15px 15px;
        color: $faq-headline-color;

        &.ui-state-active {
          background-color: $faq-highlight-color;
          border-color: transparent;
          color: $faq-headline-color-hover;
        }

        &.ui-state-hover,
        &:hover {
          color: $faq-headline-color-hover;
        }
      }

      div {
        padding: 0 45px 15px 15px;

        &.ui-accordion-content-active {
          background-color: $faq-highlight-color;
          border-bottom: 1px solid $some-grey-border;
        }

        p {
          font-size: rem(12);
          &:last-child {
            margin-bottom: 0;
          }
        }

        a {
          color: $green;
          &:hover {
            color: darken($green, 5%);
          }
        }
      }

      .ui-accordion-header {
        cursor: pointer;
      }

      .ui-accordion-header-icon {
        background-image: url(~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/arrow-bullets-open.svg);
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        top: 50%;
        margin-top: -8px;
        transition: transform 0.2s ease-in-out;
        height: 20px;
        left: initial;
        right: 20px;
        width: 17px;

        &.selectedIcon {
          @include transform(rotate(-180deg));
        }
      }
    }

    @media #{$large-up} {
      padding-top: 50px;

      h2 {
        font-size: rem(24);
        margin-bottom: 20px;
      }

      .question-answer-block {
        font-size: rem(14);
        h3 {
          font-size: rem(18);
          font-weight: normal;
          padding: 22px 30px;
        }

        div {
          padding: 0 30px 22px;

          p {
            font-size: rem(14);
          }
        }
      }
    }
  }

  #plans-pricing-faq-help {
    padding-bottom: 20px;
    @media #{$large-up} {
      padding-bottom: 60px;
    }

    h4 {
      text-align: center;
    }
  }
}
