@import 'unisporkal-styles/stylesheets/unisporkal_engine/istock/_config';

body.customer_support_container .content_wrapper .how_to_reach_us {
  margin-bottom: 24px;

  h4 {
    display: inline-block;
    margin-top: 24px;
    font-size: 21px;
    font-weight: normal;
  }

  div.header {
    margin: 20px 0px;
  }

  .mail-new {
    background-image: url(~static_content/customer_support/envelope.svg);
    background-position-y: -15px;
  }

  .mail-sent {
    background-color: $teal;
    text-align: center;
    padding: 20px 15px;
    margin-top: 30px;
    border-radius: 5px;

    @media #{$large-up} {
      padding: 33px 83px;
    }
    .icon-checkmark {
      background: url(~static_content/customer_support/checkmark-thin.svg)
        no-repeat 50% 50%;
      background-size: 200%;
      mask: url(~static_content/customer_support/checkmark-thin.svg) no-repeat
        50% 50%;
      mask-size: 200%;
      width: 30px;
      height: 20px;
      margin: auto auto 15px auto;
    }
    .label-position {
      font-size: 21px;
      line-height: 25.2px;
      font-weight: 400;
      color: #000000;
      margin-bottom: 11px;
      display: block;
    }
    p.support-message {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      color: #000000;
      margin-bottom: 30px;
    }
    a {
      font-size: 16px;
      line-height: 24px;
      font-weight: $font_weight_medium;
    }
  }

  .mail {
    background-repeat: no-repeat;
    background-size: 61px;
    .header {
      margin-bottom: 2em;
      padding-left: 82px;
      margin-bottom: 30px;
      span {
        font-weight: 500;
        vertical-align: middle;
      }
    }
  }

  .chat_desktop {
    padding: 15px 0 0 82px;
    background-image: url(~static_content/customer_support/livechat.svg);
    background-repeat: no-repeat;
    background-size: 61px;
    height: 50px;
  }

  .phone-number,
  .phone_text {
    font-weight: $font_weight_medium;
  }

  div.local-office {
    div.header {
      margin: 20px 0px 0px 0px;
    }
  }

  .office-address {
    background-image: url(~static_content/customer_support/building.svg);
    background-repeat: no-repeat;
    background-size: 61px;
    background-position-y: center;
    .company-name {
      font-weight: $font_weight_medium;
    }
  }

  select {
    float: none;
    margin-bottom: 25px;
    width: 100%;
  }

  .phone .header {
    display: none;
  }

  .phone .xp_content,
  .local-office .xp_content,
  .phone_mobile {
    padding-left: 82px;
  }

  .xp_content.phone,
  .phone_mobile {
    background-image: url(~static_content/customer_support/istock_phone.svg);
    background-position: 0%;
    background-repeat: no-repeat;
    background-size: 61px;
    min-height: 50px;

    div:nth-child(2) {
      margin-bottom: 15px;
    }

    p,
    .customer_number_mobile {
      margin-bottom: 15px;
      margin: 0px;
    }

    p.customer_number {
      margin-top: 10px;
      margin-bottom: 20px;
      font-size: 14px;
    }

    .tooltip {
      display: none;
    }
  }
}
