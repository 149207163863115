.unsupported_container {
  .main_body {
    max-width: none;
  }

  #customer-notifications-application .global-notification-container {
    display: none;
  }

  .site-width {
    width: auto;
    max-width: none;
    margin: 0;
  }

  .unsupported-header {
    height: 85px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .browser-info {
    text-align: center;

    .browser-info-content {
      padding: 100px 20px;
    }

    .headers {
      margin: 0 auto;
      max-width: 827px;
    }

    .browser-warning-icon {
      margin-bottom: 20px;
    }

    h1 {
      font-size: 1.75rem;
      line-height: 1.3em;
      margin-bottom: 18px;
    }

    h2 {
      font-size: 1.125rem;
      line-height: 1.7rem;
    }

    .browsers {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 44px 0;

      > div {
        width: 150px;
        text-align: center;

        a {
          text-decoration: none;
          font-size: 1rem;

          p {
            margin: 5px 0 0;
          }
        }
      }
    }
  }

  .customer_notifications_ribbon_portal {
    display: none;
  }

  .embeddedServiceHelpButton {
    display: none;
  }
}
