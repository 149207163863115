.home_container {
  .fourpack-container {
    max-width: 100%;
    margin: 3rem auto 0;
    overflow-x: visible; /* for screens less than min. img width */
    background-color: white;

    @media #{$istock-xxlarge-up} {
      margin: 2.5rem auto 0;
    }

    & > div:first-of-type {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 40px 0;
      position: relative;

      @media #{$istock-large-up} {
        justify-content: space-between;
        align-items: flex-start;
        padding: 72px 0;
        margin: 2.5rem auto 0;
        max-width: 1280px;
      }

      & > h2,
      & > p,
      & > .fourpack-tiles {
        @media #{$istock-large-up} {
          margin-left: 2%;
          width: 46%;
        }
      }

      & > h2,
      & > p {
        @media #{$istock-large-up} {
          text-align: left;
        }
      }

      & > h2 {
        margin-top: 40px;
        font-size: 24px !important;

        @media #{$istock-large-up} {
          margin-top: 0;
        }
      }

      & > p {
        width: 85%;
        margin-top: 1%;
        margin-bottom: 48px;
        font-size: 16px !important;

        @media #{$istock-large-up} {
          margin-top: 0;
          width: 46%;
        }
      }
    }

    .fourpack-tiles {
      display: flex;
      flex-direction: column;
      text-align: center;
      width: 85%;

      @media #{$istock-medium-up} {
        text-align: left;
      }

      @media #{$istock-large-up} {
        width: 100%;
      }

      article {
        @media #{$istock-medium-up} {
          display: flex;

          &:nth-child(1) > figure {
            margin-top: -5px;
          }

          &:nth-child(2) > figure {
            margin-top: 8px;
          }

          &:nth-child(3) > figure {
            margin-top: 5px;
          }

          &:nth-child(4) > figure {
            margin-top: 0;
          }
        }

        @media #{$istock-large-up} {
          &:last-child p {
            margin-bottom: 20px;
          }
        }

        figure {
          @media #{$istock-medium-up} {
            margin-right: 5%;
          }

          img {
            max-width: none;
          }
        }

        .value-prop--cta {
          white-space: nowrap;
        }

        h3 {
          font-size: 18px !important;
        }

        p {
          font-size: 14px !important;
          margin-bottom: 40px;
        }
      }
    }

    .right-side--content {
      /* Note: background-image:url('istock_difference.jpg') supplied from Autonomy */
      background-repeat: no-repeat;
      background-size: contain;
      min-height: 300px;
      min-width: 300px;
      margin-bottom: 40px;

      @media #{$istock-medium-up} {
        height: 527px;
        width: 514px;
        margin-top: 3%;
      }

      @media #{$istock-large-up} {
        height: 527px;
        width: 514px;
        margin-top: 0;
        margin-right: 2%;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }

      &__video-container {
        video {
          position: relative;
          left: -17px;
          top: 90px;
          height: 110px;

          @media #{$istock-medium-up} {
            left: -24px;
            top: 156px;
            height: 194px;
          }
        }
      }
    }
  }
}
