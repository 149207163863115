.front_door_container {
  .two-by-two-wrapper {
    padding-top: 55px;
    background-color: #dce1e1;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      text-align: center;
    }

    h2 {
      font-size: 1.75rem;
    }

    h4 {
      font-size: 1.125rem;
      margin-bottom: 40px;
    }

    .two-by-two {
      max-width: 71.625rem;
      margin: 0 auto;
    }

    .box-row {
      min-height: 235px;
      width: 100%;
      height: 100%;

      &:before,
      &:after {
        content: ' ';
        display: table;
      }

      &:after {
        clear: both;
      }

      &:nth-child(2n) {
        .box > .image {
          padding-right: 40px;
        }
      }

      &:before {
        content: ' ';
        display: table;
      }

      .box {
        float: left;
        width: 50%;
        margin: 0 auto;

        h4 {
          margin: 0 0 10px 0;
          text-align: left;
        }

        ul {
          list-style: initial;
          padding: 0 0 0 20px;
          font-size: 0.875rem;
          line-height: 24px;
          display: inline-block;
        }

        .image {
          padding: 0 50px 160px 0;
          display: none;
          float: left;

          &:before,
          &:after {
            content: ' ';
            display: table;
          }
        }

        .box-content {
          float: left;
          padding: 0 0 0 80px;
        }
      }

      .box-1 {
        background-repeat: no-repeat;
        background-size: contain;
        background-size: 70px;
      }

      .box-2 {
        background-repeat: no-repeat;
        background-size: contain;
        background-size: 70px;
        padding-right: 0;
      }

      .box-3 {
        background-repeat: no-repeat;
        background-size: contain;
        background-size: 70px;
      }

      .box-4 {
        background-repeat: no-repeat;
        background-size: contain;
        background-size: 70px;
        padding-right: 0;
      }
    }
  }
}

@media only screen and (max-width: 40em) {
  .front_door_container .two-by-two-wrapper h2 {
    font-size: 1.375rem;
  }

  .front_door_container .two-by-two-wrapper .box-row .box {
    position: relative;
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
    width: 83.3333333333%;
    left: 8.3333333333%;
    right: auto;
    float: left;
    padding-left: 70px;
    padding-right: 0px;
    margin-bottom: 25px;
  }

  .front_door_container .two-by-two-wrapper .box-row .box .box-content {
    padding: 0;
  }
}
