@import 'istock/front_door/top_faq';

.audio-front-page {
  background-color: #dce1e1;
  .info-container {
    padding: 50px 15px;
    max-width: $grid_max_width;
    margin-left: auto;
    margin-right: auto;
    &:after {
      content: ' ';
      display: table;
      clear: both;
    }
    .info-left {
      text-align: center;
      max-width: 726px;
      margin: 0 auto 60px auto;
      @media screen and (min-width: $grid_max_width) {
        margin: 30px auto 30px auto;
        h1 {
          width: 350px;
        }
        width: 390px;
        width: 390px;
        padding-right: 30px;
        float: left;
        text-align: left;
      }
      &:after {
        content: ' ';
        display: table;
        clear: both;
      }
    }
    .info-right {
      max-width: 726px;
      margin: auto;
      @media screen and (min-width: $grid_max_width) {
        float: left;
        border-left: 1px solid #ccd3d3;
        padding: 30px 0 0 60px;
        #better-audio {
          background-image: url(~static_content/istock/audio.svg);
        }
        #better-licensing {
          background-image: url(~static_content/istock/icon-license.svg);
        }
        #better-search {
          background-image: url(~static_content/istock/mag-glass.svg);
        }
        .box {
          background-repeat: no-repeat;
          padding-left: 70px;
        }
      }
      h2 {
        margin-bottom: 10px;
        text-align: left;
        font-size: 18px;
        font-weight: bold;
      }
      h3 {
        font-size: 16px;
      }
      .box {
        margin-bottom: 40px;
      }

      ul {
        margin: 20px 20px 0 20px;
      }
      li {
        list-style: initial;
        text-align: justify;
        font-size: 14px;
        line-height: 1.8;
      }
    }
  }
  .music-discount-box {
    @media screen and (min-width: $grid_max_width) {
      width: 350px;
    }
    margin-top: 20px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 8px;
    text-align: center;
    padding: 25px 20px 10px 20px;
    font-size: 14px;
    .percentage {
      font-size: 52px;
      line-height: 1.4;
    }
    .promo-info {
      padding: 0 15px;
      line-height: 24px;
    }
    .promo-code {
      font-weight: 500;
      margin-bottom: 20px;
    }
  }
}
