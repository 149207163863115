#promo-code {
  background-color: $grid-grey;
  height: 100%;

  @include grid(12);
  display: grid;
  padding: 0;

  .promo-code-hero-container {
    background-image: url(~static_content/istock/img_promo_code_hero.jpg);
    filter: brightness(90%);
    background-position: center 80%;
    background-size: cover;
    height: 100%;
    position: relative;

    .promo-code-container {
      @include grid-row();

      .title-container {
        @include grid-row();

        color: white;
        margin: 0 auto;
        text-align: center;

        .hero-title {
          @include grid(12);
          text-align: center;
          margin: 37px 0 30px 0;
          color: white;
          font-size: rem(28);
        }

        .hero-description {
          @include grid(12);

          font-size: rem(18);
          margin: 0 auto;
          padding-bottom: rem(40);
        }
      }

      .promo-code-box {
        @include grid-row();

        .promo-code-savings {
          @include grid(4.375);

          background-color: rgba(white, 0.2);
          border-radius: 5px;
          float: none;
          margin: 0 auto;
          padding: 20px;
          text-align: center;

          .percentage {
            font-size: rem(42);
            font-weight: 500;
            line-height: rem(50);
            text-align: right;
            color: white;
            text-align: center;
          }
          .credit-packs {
            color: white;
            font-size: rem(18);
            font-weight: 500;
            line-height: rem(24);
          }
          .promo-code {
            color: white;
            font-size: rem(16);
            line-height: rem(21);
          }
        }
      }

      .plans-and-pricing-cta {
        @include grid-row();
        margin-top: rem(30);
        display: block;
        text-align: center;
      }

      .plans-and-pricing-button {
        a {
          @include banshee-button(rgba(0, 0, 0, 0));

          font-size: 1rem;
          padding: 12px 24px 12px;
          border-color: $some-grey;

          &:hover {
            background-color: rgba($dark-grey, 0.4);
          }

          @media #{$mobile} {
            width: 60%;
          }
        }

        float: none;
        margin-left: auto;
        margin-right: auto;
        padding-left: 0;
        padding-right: 0;
      }

      .license {
        color: white;
        margin: 90px auto 0 auto;
        text-align: center;
        width: 65%;
        line-height: 1.4rem;

        p {
          font-size: 0.75rem;
          font-weight: normal;
        }
      }

      .hero-details {
        bottom: rem(20);
        position: absolute;
        right: rem(20);

        a {
          color: $dark-grid-grey;
          font-size: rem(14);
          &:hover {
            color: $green;
          }
        }
      }
    }
  }
}

@media #{$medium-down} {
  #promo-code {
    .promo-code-hero-container {
      .promo-code-container {
        .title-container {
          .hero-title {
            font-size: rem-calc(24);
          }

          .hero-description {
            font-size: rem-calc(18);
          }
        }

        .promo-code-box {
          .promo-code-savings {
            @include grid(5);

            float: none;
            margin: 0 auto;
          }
        }

        .plans-and-pricing-button {
          float: none;
          margin-left: auto;
          margin-right: auto;
          padding-left: 0;
          padding-right: 0;
        }
      }
      .hero-details {
        display: none;
      }
    }
  }
}

@media #{$small-only} {
  #promo-code {
    .promo-code-hero-container {
      .promo-code-container {
        .title-container {
          .hero-title {
            font-size: rem-calc(21);
          }
          .hero-description {
            font-size: rem-calc(16);
          }
        }
        .promo-code-box {
          .promo-code-savings {
            @include grid(10);

            float: none;
            margin: 0 auto;
          }
        }

        .plans-and-pricing-button {
          float: none;
          margin-left: auto;
          margin-right: auto;
          padding-left: 0;
          padding-right: 0;
        }

        .license {
          margin: 30px auto 0 auto;
          width: 90%;
        }
      }
    }
  }
}
