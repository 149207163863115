$sell-banner-background: rgba($dark-grey, 0.7);

.static-hero {
  height: 340px;
  padding-top: 7rem;
  padding-bottom: 12rem;
  background-size: cover;
  background-position: center center;
  position: relative;
  @media #{$mobile} {
    padding-top: 4rem;
  }

  > * {
    color: white;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
    z-index: 1;
  }

  .static-hero-background-image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  h1 {
    margin-bottom: 1rem;
    max-width: 900px;
  }

  p {
    font-size: rem(21);
    max-width: 610px;
    @media #{$mobile} {
      font-size: rem(14);
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  .hero-details {
    margin: 0 24px 10px 0;
    text-align: right;
    a {
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.75);
      color: $extra-light-grey;
      font-size: rem(16);
      &:hover {
        color: $green;
      }
    }
  }

  .hero-content {
    bottom: -16px;
    height: auto;
    position: absolute;
    width: 100%;
  }

  .hero-banner {
    background-color: $sell-banner-background;
    color: white;
    height: 70px;
    padding-top: 22px;
    @media #{$mobile} {
      line-height: 14px;
      padding: 18px 10px 0 10px;
    }
  }
  .banner-text {
    font-size: rem(18);
    height: 70px;
    text-align: center;
    @media #{$mobile} {
      font-size: rem(13);
    }
  }

  .banner-badge {
    background-color: $red;
    font-size: rem(12);
    font-weight: 500;
    margin-right: 9px;
    padding: 3px 7px 5px 7px;
    @media #{$mobile} {
      font-size: rem(9);
    }
  }
}
