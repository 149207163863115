$invision-blue: #2487ff;

.invision.front_door_container {
  .hero-invision {
    align-items: center;
    background-color: white;
    background-image: url(~static_content/istock/iStock-531784026_LandingPage-A.jpg);
    background-position-x: center;
    background-repeat-y: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    height: 575px;
    justify-content: center;
    max-height: 80vh;
    min-height: 400px;
    overflow: hidden;
    width: 100%;

    .icons {
      align-items: center;
      display: inline-flex;
      justify-content: space-between;
      margin: 40px 0;
    }

    .invision-head-icon,
    .istock-icon {
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      height: 80px;
      max-width: 200px;
      width: 33vw;
    }

    .istock-icon {
      background-image: url(~static_content/istock/istock-logo.svg);
    }

    .invision-head-icon {
      background-image: url(~static_content/istock/invision-logo-pink.svg);
      margin-right: 1vw;
    }

    .add {
      font-size: 32px;
      height: 70px;
      line-height: 65px;
    }

    > a {
      color: $dark-grey;
      border-color: $dark-grey;
      margin: 40px 0;
    }
  }

  .hero-text {
    font-size: rem(16);
    max-width: 700px;
    text-align: center;
    margin: 0 10vw;
    line-height: 32px;
  }

  .download-callout {
    align-items: center;
    border-bottom: 1px solid $some-grey-border;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 70px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 30px;
    width: 60%;
  }

  .download-text {
    align-items: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    > h3 {
      margin-bottom: 20px;
      margin-top: 30px;
    }
  }

  .sketch-icon {
    background-image: url(~static_content/istock/sketch_and_photoshop.png);
    background-repeat: no-repeat;
    background-size: contain;
    height: 100px;
    min-width: 70px;
    width: 160px;
  }

  .craft-blue {
    background-color: $invision-blue;
    background-image: url(~static_content/istock/Craft_Abstract_BG_A.jpg);
    background-position-x: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 70vh;
    margin-bottom: 60px;
    max-height: 450px;
    width: 100%;
  }

  .invision-callout {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .invision-icon {
    background-image: url(~static_content/istock/invision-logo-pink.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    height: 70px;
    max-width: 350px;
    margin-bottom: 50px;
    width: 200px;
  }

  .pricing-cta {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    > a {
      border: 1px solid $light-grey;
      border-radius: 8px;
      color: $dark-grey;
      font-size: rem(14);
      padding: 10px 25px;
      text-align: center;
    }
  }

  .has-subscription-callout {
    font-size: rem(14);
    margin-bottom: 0;
    margin-top: 20px;
    text-align: center;
  }

  .invision-text {
    flex-basis: 60%;
    max-width: 680px;
    margin: 0 15vw 25px;

    .invision-header {
      font-size: rem(24);
      margin-bottom: 20px;
      text-align: center;
    }

    .invision-info {
      font-size: rem(14);
      text-align: center;
    }
  }

  .invision-terms {
    color: $shale-grey;
    display: flex;
    font-size: rem(10);
    justify-content: center;
    line-height: 14px;
    margin: 0px 7vw;

    > p {
      max-width: 1100px;
      text-align: left;
    }
  }

  .base-cta {
    align-items: center;
    background-color: $invision-blue;
    background-image: url(~static_content/istock/Craft_Abstract_BG_B.jpg);
    background-position: -40px bottom;
    background-repeat-x: no-repeat;
    display: flex;
    height: 150px;
    justify-content: center;
    margin: 50px 0;
  }

  .craft-download {
    border: 1px solid white;
    border-radius: 10px;
    color: white;
    padding: 17px 32px;
  }

  .craft-download:hover,
  .pricing-cta > a:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }

  .site-width .three-tiles-container {
    // Odd selector used here to override other styles - I apologize
    display: flex;
    float: none;
    flex-direction: column;
    justify-content: center;
    max-width: none;
    padding: 0;
    width: auto;

    .three-steps {
      display: flex;
      flex-direction: column;
      float: none;
      margin-right: 30px;
      margin-left: 30px;
      padding: 0;
      text-align: center;
      width: auto;

      > img {
        min-height: 100px;
      }

      > p {
        font-size: rem(14);
        margin-left: auto;
        margin-right: auto;
        max-width: 310px;
      }
    }
  }
}

@media #{$medium-up} {
  .invision.front_door_container {
    .download-callout {
      flex-direction: row;
    }

    .invision-callout {
      flex-direction: row;
    }

    .invision-icon {
      margin-right: 30px;
      min-width: 260px;
      width: 30vw;
    }

    .invision-text {
      margin: 0 50px 0 0;

      .invision-info {
        text-align: left;
      }

      .invision-header {
        text-align: left;
      }
    }

    .download-text {
      flex-basis: 600px;
      margin-left: 8vw;
      text-align: left;

      > h3 {
        margin-top: 40px;
      }
    }

    .craft-blue {
      background-size: contain;
      height: 31vw;
    }

    .hero-invision {
      .add {
        margin: 0 40px;
      }
    }

    .hero-text {
      font-size: rem(24);
    }

    .site-width .three-tiles-container {
      flex-direction: row;
    }

    .pricing-cta {
      flex-direction: row;
      text-align: left;
    }

    .has-subscription-callout {
      margin-left: 40px;
      margin-top: 0;
    }
  }
}
