.trending-links {
  display: flex;
  flex-wrap: wrap;
}

.trending-links-container {
  display: flex;
  justify-content: center;
  padding-top: 25px;
  background-color: $alt-background-color;
  width: 100%;
}

.trending-links-label {
  margin: 6px 33px 0 0;
  text-align: center;
  padding-left: 5px;
}

.trending-links__link-container {
  padding: 0 5px 5px 0;
  margin: 0;
}

.trending-link {
  display: inline-block;
  color: $shale-grey;
  cursor: pointer;
  border: solid $keyword-border-color 1px;
  border-radius: 4px;
  background-color: $some-grey;
  height: 35px;
  line-height: 35px;
  padding: 0 10px;
  font-size: 0.875rem;
  font-weight: normal;

  &:hover,
  &:focus {
    background-color: $keyword-hover-color;
    color: $shale-grey;
  }
}

.trending-links__see-all .trending-link {
  color: $green;
}
