// Styling for the Tile_Three_Pack component's iStockTileThreePack skin

$max_desktop_width: 1440px;
$max_component_width: 1134px;
$max_sub_heading_width: 650px;

.front_door_container {
  .tile-three-pack {
    background-color: #dce1e1;
  }

  .tile-three-pack__content-wrapper {
    max-width: $max_component_width;
    padding: 40px 5%;
    margin: 0 auto;

    @media screen and (min-width: $max_tablet_width) {
      padding: 60px 8px;
    }

    @media screen and (min-width: $max_desktop_width) {
      padding: 60px 0;
    }
  }

  .tile-three-pack__heading-container {
    margin-bottom: 40px;
  }

  .tile-three-pack__heading {
    text-align: center;
    margin: 0 auto;
    font-size: 1.5rem;
  }

  .tile-three-pack__sub-heading {
    text-align: center;
    max-width: $max_sub_heading_width;
    margin: 0 auto;
    font-size: 1rem;
    padding-top: 10px;
  }

  .tile-three-pack__tile-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;

    li {
      margin-bottom: 40px;
    }

    li:last-child {
      margin-bottom: 0;
    }

    @media screen and (min-width: $max_tablet_width) {
      flex-direction: row;
      align-items: unset;

      li {
        margin-bottom: 0;
        margin-right: 14px;
      }

      li:last-child {
        margin: unset;
      }
    }
  }

  .tile-three-pack-tile {
    flex: 1;
    max-width: 560px;

    img {
      width: 100%;
      max-height: 370px;

      @media screen and (min-width: $max_tablet_width) {
        max-width: 370px;
        max-height: 246px;
      }
    }
  }

  .tile-three-pack-tile__heading {
    font-size: 1.3125rem;
    padding-top: 30px;

    @media screen and (min-width: $max_tablet_width) {
      margin-right: 10px;
    }
  }

  .tile-three-pack-tile__copy {
    font-size: 0.875rem;
    padding: 10px 0 30px;
    margin-bottom: 0;

    @media screen and (min-width: $max_tablet_width) {
      margin-right: 10px;
    }
  }

  .tile-three-pack-tile__button {
    // iStock green button -- gems/unisporkal_styles/app/assets/stylesheets/unisporkal-styles/stylesheets/unisporkal_engine/istock
    @extend .primary-cta;

    width: 100%;
    margin-bottom: 0;

    @media screen and (min-width: $max_tablet_width) {
      width: unset;
    }
  }
}
