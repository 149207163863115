.front_door_container {
  .three-tiles-container {
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 43px;
    padding-left: 15px;
    float: left;
    width: 70%;

    p {
      margin-top: 15px;
      margin-bottom: 30px;
    }

    .three-tiles-header {
      h2 {
        margin-top: 35px;
        margin-bottom: 35px;
      }
    }

    .three-steps {
      float: left;
      width: 33.3333%;
      padding-right: 15px;

      :last-child {
        padding-right: 0;
      }

      img {
        padding-bottom: 30px;
      }
    }
  }

  .cms-container {
    &.dark {
      background-color: #dce1e1;
    }
  }

  .tablerow-container {
    float: left;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .legal-nav-wrapper {
    .legal-navigation {
      .submenu {
        margin-left: 20px;
      }
    }
  }

  @media only screen and (width <= 640px) {
    .three-tiles-container {
      width: 100%;
      padding-right: 15px;

      .three-steps {
        width: 100%;

        ul {
          padding-bottom: 30px;
        }
      }
    }
  }
}
