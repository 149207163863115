.royalty-free-images {
  // ------NOTE--------
  //The below nth-child selector assignments could change with Autonomy changes.
  //Styling fixes should involve checking these psudo selectors.
  // ------------------

  // the first featured container
  //.featured:nth-child(4) {}

  // the second featured container
  //.featured:nth-child(8) {}

  // the second three "card" groupings
  //.featured:nth-child(11) {}

  .featured .header {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: normal;
    }
  }

  .featured:nth-child(4),
  .featured:nth-child(8),
  .featured:nth-child(11),
  .faq-contact-info,
  .inside-box .feature {
    background-color: #ffffff;
  }
  .info-container,
  .free-stock,
  .main-body .site-width {
    background-color: #f6f8fa;
  }

  // the first three list items near the top of the page
  .featured:nth-child(4) .inside-box {
    h4,
    p {
      text-align: center;
    }
  }

  .inside-box .feature {
    img {
      padding: 0;
    }

    h4,
    p {
      text-align: left;
    }
  }

  .featured:nth-child(4) {
    .inside-box {
      margin-top: 50px;

      .feature {
        background-color: transparent !important;
        border: none;
      }
    }
  }

  .featured:nth-child(4) .inside-box,
  .inside-box .feature {
    h4 {
      padding: 40px 20px 0 20px;
      font-weight: normal;
      font-size: 21px;
      line-height: 25px;
    }

    p {
      width: 100%;
      padding: 20px 20px 40px 20px;
      margin: 0;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .featured:nth-child(8) {
    padding-bottom: 0;
  }

  .featured:nth-child(11) {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 50px;

    .header {
      display: none;
    }
  }

  @media #{$medium-up} {
    .inside-box {
      display: flex;
      justify-content: space-between;
    }

    .inside-box .feature {
      width: 375px;
      border: 1px solid #c5d2d2;
    }

    .featured:nth-child(11) {
      padding-bottom: 100px;
    }

    .featured:nth-child(8) {
      padding-bottom: 10px;
    }
  }

  .static-hero,
  .faq-contact-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .static-hero {
    padding: 0;

    p {
      max-width: 780px;
    }
  }

  .faq-contact-info {
    min-width: 100%;
    margin: 0;
    padding-bottom: 110px;

    hr {
      //leaving this in case we need it - for now have been asked to remove
      // min-width: 75%;
    }
  }

  .info-container {
    @media #{$medium-up} {
      padding: 80px 40px;
    }

    .info-right ul {
      margin: 0 !important;
    }

    .info-right li {
      background-image: url(~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/icons/icon_prohibited.svg);
      background-repeat: no-repeat;
      background-size: 24px;
      list-style: none;
      padding-left: 35px;
      margin-bottom: 14px;
    }

    @media #{$large-up} {
      .inside-info {
        padding: 50px 20px;
      }
    }
  }

  .free-stock {
    section {
      padding: 50px 0 20px 0;
    }
    h3 {
      font-size: 24px;
    }
  }
}
