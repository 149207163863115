.show-local-office-info-modal {
  background: rgb(0 0 0 / 50%);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 1000;

  .modalBox {
    max-width: 930px;
    background-color: white;
    left: 0;
    margin: 5% auto auto;
    position: fixed;
    right: 0;
    font-size: 1.3em;
    max-height: 90%;
    overflow-y: auto;

    .content {
      margin-left: 80px;
      margin-bottom: 40px;
      margin-right: 40px;
    }

    .title {
      margin: 40px;

      h3 {
        font-size: 2em;
        font-weight: 100;
        text-align: left;
      }
    }

    .modalClose {
      @extend %close-icon;

      position: absolute;
      right: 15px;
      text-decoration: initial;
      top: 20px;

      &::before {
        background-color: transparent;
        color: $delta_gray;
        font-size: 36px;
        font-weight: 100;
        width: auto;
        cursor: pointer;
      }

      &::after {
        background-color: transparent;
        content: '';
      }
    }

    button {
      margin-bottom: 1em;
      border-radius: 0;

      &.btn {
        &:hover,
        &:focus {
          color: white;
        }

        &:disabled {
          color: $secondary_text;
        }
      }

      &.btn-default {
        &:hover,
        &:focus {
          color: $primary_text;
        }
      }
    }
  }

  @media screen and (width <= 600px) {
    .modalBox {
      height: 100%;
    }
  }

  @media screen and (width >= 800px) {
    .modalBox {
      .title {
      }
    }

    .title {
    }
  }

  @media screen and (width <= 800px) {
    .modalBox {
      height: 100%;
      top: -20px;
      width: 100%;

      .important-info {
        margin-left: 0;
        text-align: center;
      }

      .title {
        h3 {
          text-align: center;
        }
      }
    }
  }
}
