.unsupported_container {
  background-color: $grid-grey;

  .unsupported-header {
    background-color: $dark-grey;

    .site-logo {
      width: 110px;
      height: 47px;
    }
  }

  .browser-info {
    background-color: $grid-grey;
    color: $dark-grey;

    .browsers a {
      color: $dark-grey;

      & :hover,
      & :focus {
        color: $href_hover_color;
      }
    }
  }
}
