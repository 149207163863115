$search-width: 1132px;

.searchbar-centering-container {
  flex-grow: 1;

  @media #{$medium-up} {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

.search-bar {
  // left/right added for centering the search bar while floating/docking on IE
  left: 0;
  right: 0;
}

.search-bar-floatable {
  @media #{$medium-up} {
    max-width: $search-width;
  }
}

.searchbar-stacking-container {
  max-width: $search-width;
  width: 100%;
  z-index: 1;
  position: absolute;
  top: 110px;

  @media #{$ie11-media-feature} {
    right: 50%;
    transform: translateX(50%);
  }

  @media #{$medium-down} {
    top: 100px;
  }
}

.hero--searchbar-container {
  flex: 1 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.hero--searchbar-tagline {
  text-align: center;
  color: white;
  font-size: 2rem;

  @media #{$medium-down} {
    font-size: 1.8rem;
    width: 80%;
    margin: auto;
  }
}

.hero--searchbar-subtagline {
  text-align: center;
  color: white;
  margin-top: 5px;
  margin-bottom: 2em;
  font-size: rem(16);

  @media #{$medium-down} {
    width: 82%;
    margin: auto;
  }
}

.column-flex {
  @media #{$small-only} {
    flex-direction: column;
  }
}

.no-width {
  @media #{$medium-down} {
    width: unset;
  }
}

.no-margin {
  @media #{$medium-down} {
    margin: 0 !important;
  }
}

.mt-20 {
  @media #{$small-only} {
    margin-top: 20px !important;
  }
}
