$page-max-width: 1180px;

.resources-plugins {
  max-width: $page-max-width;
  margin: 0 auto 100px;
  section {
    display: flex;
    h2 {
      margin-bottom: 15px;
    }
  }

  .content-side {
    flex-basis: 100%;
  }

  .left-side,
  .right-side {
    flex: 1;
    flex-basis: 100%;
    padding: 0 30px;
  }

  h1 {
    text-align: center;
    margin: 40px 0 35px 0;
  }
  ul li {
    font-size: rem(14);
    text-align: left;
    list-style-type: disc;
    margin-left: 1em;
  }
  hr {
    margin: 35px 0;
  }

  .version {
    color: $light-grey;
    font-size: rem(12);
    margin: 5px 0;
  }

  .video-container {
    max-width: 600px;
    margin: auto;
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $dark-grey;
    }
  }

  #download-mac,
  #download-pc {
    display: block;
    color: $shale-grey;
    border: 1px solid $shale-grey;
    padding: 10px;
    margin-top: 15px;
    max-width: 400px;
    border-radius: 4px;
    text-align: center;
    &:hover {
      background-color: #f7f7f7;
    }
  }

  .available-for-adobe {
    font-weight: 500;
    font-size: rem(12);
  }
  .istock-plugin {
    font-size: rem(14);
  }

  @media #{$medium-down} {
    section {
      flex-direction: column;
    }

    section:nth-of-type(2n) {
      flex-direction: column-reverse;
    }
    h2 {
      text-align: center;
      margin-top: 25px;
      margin-bottom: 35px;
    }
    h1 {
      margin-bottom: 40px;
    }
    ul li {
      -webkit-text-size-adjust: none;
    }

    .image-container {
      max-width: 525px;
      margin: 0 auto;
    }
    #app-store-badge img {
      width: 175px;
    }
    .content-side {
      padding: 20px;
    }
  }
}
