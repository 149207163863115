.front_door_container {
  .legal-nav-wrapper {
    width: 30%;
    max-width: 500px;
    float: left;
    padding: 35px 0;
    margin-left: auto;
    margin-right: auto;

    @media #{$small-only} {
      width: 100%;
      padding-bottom: 0;
    }

    .legal-navigation {
      padding-left: 15px;
      padding-right: 15px;
      width: 65%;
      float: right;
      padding-top: 16px;
      text-align: left;

      @media #{$small-only} {
        float: left;
        padding-top: 0;

        h3 {
          font-size: 20px;
          margin-bottom: 20px;
        }

        .horizontal-line-desktop {
          display: none;
        }
      }

      .nav-link {
        font-size: 14px;
        line-height: 20px;
        padding-bottom: 15px;

        a {
          color: #666e6e;

          &:hover,
          &:focus {
            color: $href_color;
          }

          &.selected {
            color: $dark-grey;
            cursor: default;
          }
        }
      }

      hr {
        margin-top: 5px;
        border-bottom-width: 1px;
        border-bottom-color: $white;
      }
    }
  }

  .legal-wrapper {
    float: left;
    width: 70%;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    padding: 35px 0;

    @media #{$small-only} {
      width: 90%;
      padding-top: 0;
    }

    .legal-content {
      padding-left: 15px;
      padding-right: 15px;

      ol {
        list-style: decimal;
        margin-left: 18px;
      }

      hr {
        margin-top: 5px;
        border-bottom-width: 1px;
        border-bottom-color: $white;
      }

      .horizontal-line-mobile {
        display: none;
      }

      .list-block,
      .legal-subheader {
        margin-bottom: 50px;
      }

      .list-block {
        h3 {
          margin-bottom: 10px;
        }

        ul li {
          list-style: disc;
          margin-left: 18px;
        }
      }

      .special-paragraph {
        background-color: #f4f6f6;
        border: 1px solid #ccd3d3;
        padding: 1rem;
      }

      .legal-table {
        background-color: #f4f6f6;
        border-collapse: collapse;
        margin-bottom: 30px;
        width: 100%;

        th {
          padding-left: 25px;
          width: 33%;
          vertical-align: top;
        }

        th,
        td {
          border: 1px solid #ccd3d3;
          padding: 1rem;
          text-align: left;
        }
      }

      @media #{$small-only} {
        .horizontal-line-mobile {
          display: block;
          margin-top: 10px;
        }
      }
    }
  }
}
