.front_door_container {
  .media-container {
    max-width: 71.625rem;
    margin: 0 auto;
  }

  .help {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
    clear: both;
    margin-bottom: 60px;
    h2 {
      border-top: solid 1px #ccd3d3;
      padding-top: 50px;
    }
  }

  .two-tile {
    float: left;
    width: 50%;
    margin: 56px auto 60px;
    padding: 0 20px;
    h5 {
      padding-bottom: 20px;
    }
  }
  .two-tile:nth-child(2n) {
    border-left: solid 1px #ccd3d3;
    padding-left: 80px;
  }
  .tile-copy {
    h3 {
      font-size: 1.25rem;
      margin-bottom: 25px;
    }
    li {
      background-image: url(~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/icons/icon_prohibited.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-size: 24px;
      list-style: none;
      margin-bottom: 14px;
      padding-left: 35px;
    }
  }
  #toll-free {
    margin: 0 auto;
    padding: 15px 0 0 0;
    div {
      background-image: url(~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/icons/callus.svg);
      background-repeat: no-repeat;
      display: table;
      height: 60px;
      margin: 0 auto;
      background-position: top;
      span {
        font-size: 1.5em;
        position: relative;
        bottom: -60px;
      }
    }
  }
  .info-contact {
    padding-top: 40px;
    margin: 0 auto;
    max-width: 1146px;
    border-top: solid 1px #ccd3d3;
    clear: both;
    h2 {
      text-align: center;
    }
    .inside-info {
      padding-top: 0px;
    }
    .inside-info .info-left {
      border-right: none;
      padding-right: 20px;
    }
    .inside-info .info-right {
      padding-left: 20px;
    }
  }
  .license-header {
    max-width: 71.625rem;
    padding: 60px 0;
    width: 100%;
    margin: 0 auto;
    h1 {
      text-align: center;
    }
    h3 {
      text-align: center;
      padding: 20px 30px;
    }
  }
  .extended-license-wrapper {
    padding-top: 55px;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      text-align: center;
    }
    h2 {
      font-size: 1.75rem;
    }
    h4 {
      font-size: 1.125rem;
      margin-bottom: 40px;
    }
    background-color: #dce1e1;
    .box-row {
      &:before,
      &:after {
        content: ' ';
        display: table;
      }
      &:after {
        clear: both;
      }
      &:nth-child(2n) {
        .box > .image {
          padding-right: 40px;
        }
      }
      min-height: 235px;
      max-width: 71.625rem;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      &:before {
        content: ' ';
        display: table;
      }
      .box {
        h4 {
          margin: 0 0 10px 0;
          text-align: left;
        }
        .image {
          &:before,
          &:after {
            content: ' ';
            display: table;
          }
          padding: 0 50px 160px 0;
          display: none;
          float: left;
        }
        .box-content {
          float: left;
          padding: 0 0 0 80px;
        }
        ul {
          list-style: initial;
          padding: 0 0 0 20px;
          font-size: 0.875rem;
          line-height: 24px;
          display: inline-block;
        }

        float: left;
        width: 50%;
        margin: 0 auto;
      }
      .box-1 {
        background-image: url(~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/icons/icon_newspaper.svg);

        background-repeat: no-repeat;
        background-size: contain;
        background-size: 52px;
      }
      .box-2 {
        background-image: url(~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/icons/icon_package.svg);
        background-repeat: no-repeat;
        background-size: contain;
        background-size: 52px;
        padding-right: 0;
      }
      .box-3 {
        background-image: url(~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/icons/icon_share.svg);
        background-repeat: no-repeat;
        background-size: contain;
        background-size: 52px;
      }
      .box-4 {
        background-image: url(~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/icons/icon_template.svg);
        background-repeat: no-repeat;
        background-size: contain;
        background-size: 52px;
        padding-right: 0;
      }
    }
  }
}

@media only screen and (max-width: 40em) {
  .front_door_container .extended-license-wrapper h2 {
    font-size: 1.375rem;
  }

  .front_door_container .extended-license-wrapper .box-row .box {
    position: relative;
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
    width: 83.3333333333%;
    left: 8.3333333333%;
    right: auto;
    float: left;
    padding-left: 70px;
    padding-right: 0px;
    margin-bottom: 25px;
  }

  .front_door_container .two-tile {
    width: 100%;
    margin-top: 15px;
    position: relative;
    width: 83.3333333333%;
    left: 6.3333333333%;
    padding-left: 0;
    right: auto;
    float: left;
    margin-bottom: 0;
    &:nth-child(2n) {
      border-top: solid 1px #ccd3d3;
      border-left: none;
      padding-top: 25px;
      padding-left: 0;
      margin-bottom: 20px;
    }
  }
  .front_door_container .extended-license-wrapper .box-row .box .box-content {
    padding: 0;
  }
}
