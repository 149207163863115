@use '~@unisporkal/alliance-ui-theme/dist/styles/iStock/index' as iStockTheme;
@use '~@unisporkal/alliance-ui-theme/dist/styles/common/index' as commonTheme;
@import 'unisporkal-styles/stylesheets/unisporkal_engine/istock/_config';

#hero--video-first.eoq-hero {
  height: 600px !important;
  max-height: 100%;
  z-index: 9;

  @media #{$istock-small-up} {
    height: 520px !important;
  }
}

.eoq-react-search-bar {
  width: 100%;
  z-index: 2;
}

.eoq-hero--header-container {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  padding-top: 20px;

  @media #{$istock-medium-up} {
    padding-top: 40px;
  }
}

.eoq-hero--header-tagtext {
  @extend .badge-new-text;

  background-color: commonTheme.$true-white;
  color: iStockTheme.$is-black;
  font-size: rem(14);
  font-weight: normal;
  padding: 2px 22px;
  margin-right: 10px;
  border-radius: 30px;
  margin-bottom: 15px;
}

.eoq-hero--header-tagline {
  text-align: center;
  color: commonTheme.$true-white;
  font-size: 1.8rem;
  margin-bottom: 15px;
  padding: 0 8px;
  line-height: 1.3em;

  @media #{$istock-medium-up} {
    font-size: 2rem;
    margin-bottom: 0;
  }
}

.eoq-hero--header-subtagline {
  text-align: center;
  color: commonTheme.$true-white;
  margin-top: 25px;
  margin-bottom: 15px;
  font-size: rem(21);
  max-width: 900px;
  width: 90%;

  @media #{$istock-medium-up} {
    margin-bottom: 30px;
    width: 100%;
  }
}

a.hollow-cta.eoq-hero {
  color: commonTheme.$true-white;
  border-color: iStockTheme.$primary-dark;
  background-color: iStockTheme.$primary;
  min-width: 180px;
  width: auto;
  margin-top: 20px;

  &:hover,
  &:focus {
    color: commonTheme.$true-white;
    background-color: iStockTheme.$primary;
  }
}

.end-of-quarter-stacking-container {
  width: 100%;
  z-index: 1;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media #{$istock-medium-up} {
    max-width: 90%;
    position: static;
  }

  @media #{$istock-large-up} {
    top: 50px;
    position: absolute;
  }
}
