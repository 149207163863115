// Styling for the Tile_Two_Pack component's iStockTileTwoPack skin

$max_desktop_width: 1440px;
$max_component_width: 1134px;
$max_sub_heading_width: 650px;

.front_door_container,
.landing-tiles {
  .tile-two-pack {
    text-align: center;
  }

  .tile-two-pack--light {
    background-color: #e8eded;
  }

  .tile-two-pack--dark {
    background-color: #dce1e1;
  }

  .tile-two-pack__content-wrapper {
    max-width: $max_component_width;
    padding: 40px 5%;
    margin: 0 auto;

    @media screen and (min-width: $max_tablet_width) {
      padding: 60px 8px;
    }

    @media screen and (min-width: $max_desktop_width) {
      padding: 60px 0;
    }
  }

  .tile-two-pack__sub-heading {
    max-width: $max_sub_heading_width;
    margin: 0 auto;

    h2 {
      font-size: 1.5rem;
    }

    h4 {
      font-size: 1rem;
      padding-top: 10px;
    }
  }

  .tile-two-pack__tile-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
    margin-bottom: 0;

    li:first-child {
      margin-bottom: 40px;
    }

    @media screen and (min-width: $max_tablet_width) {
      flex-direction: row;
      align-items: unset;

      li:first-child {
        margin-bottom: 0;
        margin-right: 14px;
      }
    }
  }

  .tile-two-pack-tile__image {
    &:hover,
    &:focus {
      filter: brightness(0.85);
    }
  }

  .tile-two-pack-tile {
    max-width: 560px;
    position: relative;

    .tile-two-pack-tile__info {
      opacity: 0;
      border: none;
      font-size: 16px;
      padding: 0 8px 0 0;
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 1;
      text-shadow: 1px 1px 2px rgb(0 0 0 / 75%);
      color: white;

      &:hover,
      &:focus {
        color: $green;
      }

      @media #{$small-only} {
        display: none;
      }
    }
  }

  .tile-two-pack-tile:hover .tile-two-pack-tile__info,
  .tile-two-pack-tile:focus .tile-two-pack-tile__info {
    opacity: 1;
  }

  .tile-two-pack-tile__heading {
    font-size: 1.5rem;
  }

  .tile-two-pack-tile__copy {
    font-size: 0.875rem;
    padding-top: 30px;

    p {
      padding-top: 10px;
      margin-bottom: 0;
    }

    h2 {
      font-size: 1.5rem;
    }

    h4 {
      font-size: 1.3125rem;
    }
  }

  .tile-two-pack-tile__button {
    // iStock green button -- gems/unisporkal_styles/app/assets/stylesheets/unisporkal-styles/stylesheets/unisporkal_engine/istock
    @extend .primary-cta;

    width: 100%;
    margin-top: 30px;
    margin-bottom: 0;

    @media screen and (min-width: $max_tablet_width) {
      width: unset;
    }
  }
}
