div.modern-slavery {
  @media only screen and (width >= 1024px) {
    width: 1024px;
  }

  padding: 50px 40px;
  margin-left: auto;
  margin-right: auto;

  ul {
    list-style-type: disc;
    margin-left: 22px;
    margin-bottom: 24px;
  }

  p,
  li,
  div {
    font-size: 14px;
  }

  h3 {
    padding-top: 26px;
    font-size: 21px;
  }

  h1,
  h3 {
    margin-bottom: 10px;
  }

  p.first {
    margin-bottom: 0;
  }

  div {
    font-weight: 500;
  }
}
