.front_door_container {
  .dark {
    background-color: $dark-grid-grey;
  }

  .gray-ribbon-promo {
    background-color: rgb(12 13 13 / 70%);
    color: #fff;
    padding: 35px 0;
    text-align: center;
    width: 100%;
    float: left;

    p {
      max-width: 910px;
      margin: auto;
    }
  }

  .twotiles-contentwrapper {
    width: 100%;
    float: left;

    .imagewcopy-wrapper {
      padding: 40px 0 50px;
      max-width: 950px;
      margin: 0 auto;

      .mb-10 {
        margin-bottom: 10px;
      }

      .imagewcopy {
        width: 49.5%;
        float: left;
        margin-bottom: 30px;
        padding-top: 10px;

        .second-block {
          float: right;
        }

        .imagewcopy-list {
          list-style: disc;
          margin-left: 18px;
          padding: 5px 0;
        }

        > ul > li {
          width: 88%;
        }
      }
    }
  }

  .imagewcopy-wrapper {
    > ul > li:first-child {
      border-right: 1px solid $some-grey-border;
    }
  }

  .imagewcopy-middle {
    padding-bottom: 50px;
    max-width: 950px;
    margin: 0 auto;
  }
}
