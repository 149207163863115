@use 'sass:math';
@import 'unisporkal-styles/stylesheets/unisporkal/getty/config/_media_breakpoints';
@import 'unisporkal-styles/stylesheets/unisporkal/shared/config/_ie_support';

$hero-height: 650px;
$hero--video-height: 495px;
$hero-height-mobile: $hero-height * 0.46;
$hero-height-medium: 350px;
$sell-banner-background: rgba($dark-grey, 0.7);
$istock-hero-black: #0c0d0d;

.home_container,
.front_door_container {
  .headline {
    @include grid-row();
    @include grid(12, $grid_align: center);

    padding-top: 10px;

    @media #{$small-only} {
      h2 {
        font-size: rem(20);
        margin: 25px 0 20px;
      }
    }
  }
  .header {
    margin: 37px 18px 30px;
    text-align: center;
  }

  .img-zoom {
    > div {
      overflow: hidden;
    }
    img {
      display: block;
      transform: scale(1);
      transition: all 0.2s ease-out;
    }
  }
  .img-zoom:hover img {
    opacity: 0.8;
    transform: scale(1.03);
  }
  .img-zoom:hover + .tile-info {
    opacity: 1;
    transition: all 0.2s ease-in;
  }

  .img-zoom:hover + .tile-two-pack-tile__info {
    opacity: 1;
    transition: all 0.2s ease-in;
  }

  .promo-banner {
    background-color: #dce1e1;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .promo-banner section {
    max-width: 71.625rem;
    margin: 0px auto;
  }

  #category-tiles {
    @include grid-row();
    @include clearfix();

    width: 96%;

    .col {
      @include grid(4, $grid_padding_l: 0, $grid_padding_r: 0);
      float: left;
      padding-right: 0;
      padding-left: 0;

      & > div {
        margin: 6px;
      }

      .tile {
        background-color: $white;
        border: 1px solid $extra-light-grey;
        margin-bottom: 12px;
        padding: 8px;
        position: relative;

        &.last {
          margin-bottom: 0;
        }

        h5 {
          margin: 12px 0 5px;
          text-align: center;
        }

        .tile-info {
          opacity: 0;
          background: $white;
          color: $light-grey;
          font-size: 12px;
          padding: 0 8px 0 0;
          position: absolute;
          top: 0;
          z-index: 1;

          .camera {
            background-image: url('~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/camera.svg');
            background-repeat: no-repeat;
            background-size: 24px;
            display: inline-block;
            height: 24px;
            width: 24px;
            position: relative;
            top: 9px;
          }
        }
      }
      .tile:hover .tile-info {
        opacity: 1;
        color: $href_color;
      }
    }

    @media #{$small-only} {
      .col {
        @include grid(6, $grid_padding_l: 0, $grid_padding_r: 0);

        &.col1 {
          @include grid(12, $grid_padding_l: 0, $grid_padding_r: 0);

          & > div {
            @include grid(6, $grid_padding_l: 0, $grid_padding_r: 0);
            margin: 0 0 6px;
          }
          .tile {
            margin: 0 6px;
          }
        }

        .tile {
          h5 {
            font-size: rem(13);
            margin: 9px 0 3px;
          }
          .tile-info {
            display: none;
          }
        }
      }

      .tile-two-pack-tile__info {
        display: none;
      }
    }
  }
  #category-listing {
    @include grid-row();
    @include clearfix();
    padding-bottom: 35px;
    text-align: center;

    .mob-col div {
      @include grid(3);
      float: left;
      padding-left: 15px;
      padding-right: 15px;
    }
    a {
      font-size: rem(16);
      font-weight: 500;
    }

    @media #{$small-only} {
      h3 {
        font-size: rem(20);
        margin: 20px 0;
      }
      .mob-col {
        @include grid(12);

        > div {
          @include grid(6);
        }
      }
      ul {
        font-size: 75%;
        margin: 0;
      }
      a {
        font-size: rem(13);
        font-weight: 500;
      }
    }
  }
  .free-stock {
    background-color: $grid-grey;
    padding-bottom: 60px;
    width: 100%;

    section {
      @include grid-row();
      @include clearfix();

      img {
        width: 100%;
      }

      .tile {
        @include grid(3, $grid_padding_l: 0, $grid_padding_r: 0);
        float: left;
        margin-bottom: 12px;
        padding-left: 0;
        padding-right: 0;
        position: relative;
        overflow: hidden;

        .img-zoom {
          overflow: hidden;
        }

        .tile-two-pack-tile__info {
          opacity: 0;
          border: none;
          background: $white;
          color: $white;
          font-size: 12px;
          padding: 0 8px 0 0;
          position: absolute;
          top: 2px;
          left: 2px;
          z-index: 1;
        }

        .camera {
          background-image: url('~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/camera.svg');
          background-repeat: no-repeat;
          background-size: 24px;
          display: inline-block;
          height: 24px;
          width: 24px;
          position: relative;
          top: 9px;
        }

        a {
          background-color: $white;
          border: 1px solid $extra-light-grey;
          color: black;
          display: block;
          margin: 0 6px;
          transition: all 0.25s ease-in;
          text-align: center;
          padding: 8px;

          & > div {
            position: relative;
          }

          & > div > div {
            background-size: auto 33px;
            background: no-repeat center center;
            display: inline-block;
            height: 23px;
            left: 8px;
            position: absolute;
            top: 8px;
            width: 29px;
          }

          .decoration-video,
          .decoration-audio {
            background: no-repeat;
            background-size: contain;
          }
          .decoration-video {
            @include video-icon;
          }
          .decoration-audio {
            @include audio-icon;
          }

          &:hover {
            opacity: 0.8;
          }

          h5 {
            margin: 5px 0 3px;
            text-align: center;
          }
        }
      }

      .tile:hover .tile-two-pack-tile__info {
        opacity: 1;
        color: $href_color;
      }
    }

    @media #{$small-only} {
      padding-bottom: 20px;

      section {
        .tile {
          @include grid(6, $grid_padding_l: 0, $grid_padding_r: 0);
          a h5 {
            font-size: rem(13);
          }
        }
        h3 {
          margin: 20px;
        }
      }
    }
  }
  .sell-banner {
    background-color: $dark-grey;
    color: $white;
    font-size: rem(21);
    padding: rem(35) 0;
    margin: 0;

    @media #{$small-only} {
      background-color: $dark-grey;
      color: $white;
      font-size: rem(18);
      margin: 0;
      padding: 20px 0;
      width: 100%;

      span {
        display: block;
        margin: 0 auto;
        width: 90%;
      }
    }
  }

  .hero {
    background: no-repeat center center;
    background-size: cover;
    height: $hero-height;
    position: relative;
    width: auto;
    display: flex;
    flex-direction: column;

    .hero--image-background {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .hero--media-container {
      width: 100%;
      max-width: 100%;
      height: 100%;
      max-height: 100%;
      overflow: hidden;
      position: absolute;

      .hero--image-background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    @media #{$small-only} {
      background-attachment: scroll;
      height: $hero-height-mobile !important;
      .hero-details,
      .hero--video-first-details {
        display: none;
      }
      .sell-banner {
        padding: 20px 0;
      }
      #hero--video-first {
        max-height: $hero-height-mobile;
      }
    }

    @media #{$medium-down} {
      height: $hero-height-medium !important;
      .sell-banner {
        font-size: rem(18);
      }
    }
    .sell-banner {
      background-color: $sell-banner-background;
      bottom: 0;
      position: relative;
      width: 100%;
    }

    .hero--video-first-details {
      position: absolute;
      right: 40px;
      bottom: 30px;
      a {
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.75);
        color: $extra-light-grey;
        font-size: rem(16);
        &:hover {
          color: $green;
        }
      }
    }

    .hero-details {
      position: absolute;
      right: 40px;
      top: -40px;
      a {
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.75);
        color: $extra-light-grey;
        font-size: rem(16);
        &:hover {
          color: $green;
        }
      }
    }
  }

  #hero--video-first {
    max-height: $hero--video-height;
  }
}

.home_container {
  // TODO: Clean up landing page bg colors per https://app.clickup.com/t/wdg1pc
  .main_body {
    background-color: #f5f5f5 !important;
  }

  .free-stock {
    background-color: transparent;
  }

  #hero--video-first ~ .landing-tiles {
    .promo-banner {
      background-color: white;
    }
  }
}

// NOTE: What we want is for the video to automatically scale to fit the
// size of .hero-video. Videos are currently expected to be inherently
// 1920x1080.
// On mobile/small widths, which are taller than it is wide, scale the
// video to fit the height, and then crop left & right.
// On everything else which is wider than it is tall, we want to scale
// to fit the width, and then crop the top and bottom to fit the height
// constraint.
//
// In everything but IE11 and Edge, object-fit: cover works perfectly; we
// just need to specify our height constraint.
//
// IE11 and Edge (as of v18, for video-only) do not support
// object-fit: cover. Instead we have to fake it manually. These become
// the "default" properties for .video-home-page-hero and
// .video-home-page-hero__video.
//
// IE11 doesn't support @supports, and so it ignores everything in the
// @supports sections. This allows us to target IE11 in CSS without issue;
// see details in comments below.
//
// Edge supports @supports, but fails on @supports(object-fit:cover), so it
// will also ignore the overrides.
//
// All other browsers that we support also support @supports. They see the
// @supports test, pass it, and so apply the styling we really want. That
// styling must override all the hacks we put in for IE11.

// Note that Edge supports object-fit: cover, but *only* on img elements,
// not video. That means that the @supports test passes, but doesn't give
// us the behaviour we want. So, we need to disable this enhancement for
// Edge and provide the alternate implementation below.

@supports (object-fit: cover) and
  (not ($edge-supports-property: $edge-supports-value)) {
  .hero--video-background {
    object-fit: cover;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
    position: absolute;

    @media #{$medium-up} {
      width: 100%;
    }
  }
}

// IE11 doesn't support @supports, and Edge doesn't have
// a reliable media query that can use to target it, so we have
// no mechanism to target both IE11 and Edge simultaneously.
// Instead, create a mixin that we can then apply to the individual
// IE11- and Edge-specific targeting methods.
// All of these styles are to work around the lack of object-fit: cover
// support in IE11 and Edge.
@mixin ieVideoSize($next-largest-media-breakpoint) {
  $width: $next-largest-media-breakpoint;
  height: ceil(math.div($width, 16) * 9);
  width: $width;
}

@mixin hero--video-background-without-object-fit-cover {
  .hero--video-background {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @include ieVideoSize($medium-media-breakpoint);

    @media #{$medium-up} {
      @include ieVideoSize($large-media-breakpoint);
    }

    @media #{$large-up} {
      @include ieVideoSize($xlarge-media-breakpoint);
    }

    // IE won't scale the video beyond it's native resolution,
    // so this is the end of the line as far as breakpoints go.
    @media #{$xlarge-up} {
      height: 1080px;
      width: 1920px;
    }
  }
}

@media #{$ie11-media-feature} {
  @include hero--video-background-without-object-fit-cover;
}

@supports ($edge-supports-property: $edge-supports-value) {
  @include hero--video-background-without-object-fit-cover;
}
