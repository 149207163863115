@media (width <= 320px) {
  body.customer_support_container .content_wrapper {
    .customer_support {
      div.content_creators_mobile {
        margin-left: -20px;
        margin-right: -20px;
      }
    }
  }
}

@media (width <= 768px) {
  body.customer_support_container .content_wrapper {
    .customer_support {
      div.left.responsive {
        max-width: none;
        width: 100%;
        padding-right: 24px;
        padding-left: 24px;
      }

      div.right.responsive {
        max-width: 478px;
        width: auto;
        padding-left: 34px;
        padding-right: 34px;
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}

@media (width <= 1024px) and (width >= 769px) {
  body.customer_support_container .content_wrapper {
    .customer_support {
      div.left.responsive {
        max-width: none;
        width: calc(50% - 41px);
        margin-left: 24px;
        margin-right: 34px;
      }

      div.right.responsive {
        width: calc(50% - 41px);

        // width: auto;
        margin-left: 0;
        margin-right: 24px;
      }
    }
  }
}

@media screen and (max-width: $max_mobile_width) {
  body.customer_support_container .content_wrapper {
    div.how_to_reach_us {
      hr {
        margin-left: -40px;
        margin-right: -40px;
      }
    }
  }
}

@media (width >= 1025px) {
  body.customer_support_container .content_wrapper {
    .customer_support {
      div.right.responsive {
        margin-left: 61px;
      }
    }
  }
}
