.front_door_container {
  .static-hero {
    display: flex;
    flex-direction: column;

    p {
      max-width: 780px;
    }
  }
}
