$page-max-width: 1180px;
$hero-image-background: #fc7777;

.multiuser-block {
  .plans-and-pricing-ribbon {
    display: none;
  }

  .contact-sales {
    text-align: center;
    padding: 0 25px;
    max-width: $page-max-width;
    margin: 0 auto 50px;

    hr {
      margin-left: 25px;
      margin-right: 25px;
    }
  }

  .featured-container {
    text-align: center;
    align-items: center;
    justify-content: center;
    max-width: $page-max-width;
    margin: 0 auto;
    padding: 0 25px;

    .featured-header {
      flex-basis: 100%;
      flex-direction: row;
      color: $dark-grey;
      margin: 50px 0 16px;

      h2 {
        font-size: 28px;
        margin-bottom: 10px;
      }
    }

    .cms-grid-8 {
      flex: 3;
      padding: 0 30px;
      margin-bottom: 30px;

      p {
        font-size: 14px;
      }

      img {
        height: 80px;
        margin-bottom: 20px;
      }
    }

    @media #{$medium-down} {
      .cms-grid-8 {
        display: block;
        flex-basis: 100%;

        p {
          margin: 0 auto;
          max-width: 80%;
        }

        img {
          margin-bottom: 10px;
        }
      }
    }
  }

  @media #{$medium-down} {
    .hero-details {
      display: none;
    }
  }
}
