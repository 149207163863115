// shims that replace original foundation css mixins and functions

@use 'sass:math';

@mixin load-icon($name) {
  background-image: url(~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/#{$name}.svg);
  background-repeat: no-repeat;
  background-size: contain;
}

@mixin clearfix() {
  zoom: 1;

  &::before,
  &::after {
    content: '\0020';
    display: block;
    height: 0;
    overflow: hidden;
  }

  &::after {
    clear: both;
  }
}

@mixin transform($function, $prefixes: $prefix_defaults) {
  @each $prefix in $prefixes {
    #{$prefix}transform: $function;
  }
}

@mixin grid-row {
  width: 100%;
  margin: 0 auto;
  max-width: 1146px;
}

@mixin grid-column(
  $columns: false,
  $center: false,
  $offset: false,
  $collapse: false,
  $float: true
) {
  // If collapsed, get rid of gutter padding
  @if $collapse {
    padding-left: 0;
    padding-right: 0;
  }

  // Gutter padding whenever a column isn't set to collapse
  // (use $collapse:null to do nothing)
  @else if $collapse == false {
    // padding-left: $column-gutter / 2;
    // padding-right: $column-gutter / 2;
    padding-left: 15px;
    padding-right: 15px;
  }

  // If a column number is given, calculate width
  @if $columns {
    width: percentage(math.div($columns, 12));
  }

  @if $float {
    @if $float == left or $float == true {
      float: $default-float;
    } @else if $float == right {
      float: $opposite-direction;
    } @else {
      float: none;
    }
  }

  // If centered, get rid of float and add appropriate margins
  @if $center {
    margin-left: auto;
    margin-right: auto;
    float: none;
  }

  // If offset, calculate appropriate margins
  @if $offset {
    margin-#{$default-float}: percentage($offset/12) !important;
  }
}
