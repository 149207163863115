/**
* Contains styles pertaining to the why subscriotions page
*/
.front_door_container {
  .subscriptions-grey-ribbon {
    background-color: $sell-banner-background;
    color: $white;
    padding: 35px 0;
    text-align: center;
    font-size: 1.3125rem;
    width: 100%;
    float: left;
    margin-bottom: 35px;
  }

  .vimeo-video-container {
    position: relative;
    padding-bottom: 50%;
    height: 0;
    overflow: hidden;
    background-color: #191e24;
    clear: left;

    iframe,
    object,
    embed {
      position: absolute;
      top: 11%;
      left: 15%;
      width: 69%;
      height: 78%;
      border: 9px solid white;
    }
  }

  .fourpack-container {
    max-width: 946px;
    margin-left: auto;
    margin-right: auto;

    h2 {
      text-align: center;
      margin-bottom: 18px;
    }

    h3 {
      margin-bottom: 18px;
      min-height: 50px;
    }

    div.fourpack-tiles {
      > article {
        width: 22.5%;
        float: left;
        margin-right: 10px;
        margin-left: 10px;
      }
    }

    figure {
      height: 150px;
      position: relative;
      margin-bottom: 18px;

      img {
        position: absolute;
        bottom: 0;
        right: 25%;
      }
    }
  }

  .plans-and-pricing-button-box {
    margin-left: auto;
    margin-right: auto;
  }

  .message-container {
    padding-bottom: 150px;
    text-align: center;

    .header {
      margin-left: 2%;
      margin-right: 2%;
      padding-top: 37px;
      padding-bottom: 30px;
    }
  }

  @media #{$small-only} {
    .fourpack-container {
      width: 95%;

      div.fourpack-tiles {
        width: 95%;
        margin-left: auto;

        > article {
          width: 42.5%;
        }
      }

      figure {
        img {
          right: inherit;
        }
      }
    }

    .plans-and-pricing-button-box {
      width: 83.3333%;
    }

    .subscriptions-grey-ribbon {
      font-size: 1.125rem;
      padding: 20px 0;
    }
  }
}
