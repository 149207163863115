@import 'shared/legacy/customer_support/_tooltip';
@import 'shared/legacy/customer_support/_local_office_info_modal';

// @import "_mobile"; included at the bottom

#live_chat_request_container {
  &.disabled,
  .disabled {
    color: #a8a8a8;
    font-style: italic;
    text-decoration: none;
    cursor: default;
  }
}

#footer {
  margin: 0;
}

body.customer_support_container {
  .content_wrapper {
    .customer_support {
      overflow: hidden;
      margin: 0 auto;
      display: table;

      div.custom_notice {
        max-width: 1070px;
        margin: auto auto 40px;
      }

      div.left {
        display: inline-block;
        width: calc(50% - 32px);
        max-width: 610px;
        vertical-align: top;
      }

      div.right {
        display: inline-block;
        width: calc(50% - 32px);
        max-width: 610px;
      }

      div.top_faqs {
        padding: 40px;

        hr {
          margin-top: 20px;
        }

        .faq {
          margin-top: 20px;
          cursor: pointer;
        }

        .question {
          display: inline-block;
          width: 95%;
        }

        .toggler,
        .section-toggler {
          float: right;
          width: 10px;

          a {
            font-size: 20px;
            text-decoration: none;
          }
        }

        .answer {
          cursor: default;

          p:first-child {
            margin-top: 10px;
          }

          p {
            margin-bottom: 0;
          }
        }

        h4 {
          display: inline-block;
          margin: 0;
        }
      }

      div.phone_mobile {
        a.button {
          background-color: rgb(100 178 227);
          font-weight: bold;
          font-size: 16px;
          line-height: 16px;
          color: white;
        }

        div.hours_text {
          p {
            margin: 10px 0;
            padding: 0;
          }
        }

        hr {
          margin: 20px 0 0;
        }
      }

      div.tooltip_mobile {
        margin: 0;
        padding: 0;

        .tooltip::before {
          vertical-align: text-top;
          font-size: 30px;
          line-size: 40px;
          align-content: left;
          margin: 0;
          padding: 0;
        }

        .tooltip {
          float: left;
          margin: -7px 0 0 -5px;
          padding: 0;
        }

        .tooltip_contents {
          left: 0;
        }

        .customer_number_mobile {
          vertical-align: middle;
          margin: 0 0 15px 25px;
        }
      }

      div.attribution_box {
        width: 250px;
        position: relative;
        margin: 20px;

        .transparent {
          padding: 20px; /* Fallback for older browsers without RGBA-support */
          background: rgb(92 89 91 / 70%);
        }

        .getty-logo {
          width: 125px;
          height: 21px;
        }

        .photographer {
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          color: white;
        }
      }

      h4 {
        font-size: 18px;
        line-height: 24px;
        font-weight: bold;
        margin-bottom: 20px;
      }

      div.multiple_phone_number_text {
        p {
          margin-bottom: 20px;
          margin-top: 0;
        }
      }
    }
  }
}
