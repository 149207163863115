.customer_support_container {
  .content_wrapper {
    .customer_support {
      .email_container {
        @media #{$medium-down} {
          margin-left: 0;
        }

        select {
          width: 100%;
          margin: 5px 0 15px;
        }

        p.thank-you-message {
          margin: 0;
          padding-top: 5px;
        }

        form {
          margin: 0;
        }

        select[disabled='disabled'] {
          padding: 0.3611em 0.4167em;
        }

        ng-message,
        .error-message {
          padding-top: 20px;
          display: block;
        }

        li.small-space {
          margin-bottom: 10px;

          @media #{$medium-down} {
            margin-bottom: 16px;
          }
        }

        text-align: left;

        textarea {
          height: 10.6em;
          padding: 1.1em 0 0 1.2em;
          resize: vertical;
          max-height: 450px;
          margin: 10px 0 -6px;

          @media #{$medium-down} {
            font-size: rem(14);
            height: 12.15em;
            padding: 0.9em 0 0 1.4em;
          }
        }

        fieldset ol {
          margin-bottom: 0;

          input {
            height: 54px;
            margin-top: 10px;
            padding-left: 1.2em;

            @media #{$medium-down} {
              font-size: rem(14);
              padding-left: 1.4em;
            }
          }
        }

        button {
          @include banshee-button;

          display: block;
          margin: 10px 0;
          width: 100%;

          .loader {
            @include bounce-loader;
          }
        }

        span.clarification {
          font-size: 11px;
          margin-left: 8px;
        }

        p.error-message {
          padding: 20px;
          background-color: $school-bus-yellow;
          text-align: left;
        }

        .assisting-type-container {
          display: inline-block;
          padding-top: 20px;
          width: 100%;

          .radio-button {
            display: inline-block;
            margin-left: 0;

            input[type='radio'] {
              margin-top: 0;
            }
          }

          label {
            display: inline-block;
            cursor: pointer;
          }

          .select-container {
            padding-left: 22px;
          }

          input {
            height: 13px;
          }

          &:last-of-type {
            padding-top: 0;
          }
        }

        .support-message,
        .g-recaptcha-protected-message {
          font-size: rem(14);
          padding-top: 2px;
          text-align: center;

          @media #{$medium-down} {
            font-size: rem(12);
            line-height: 1.1em;
          }
        }
      }
    }
  }

  .email_container_visible {
    visibility: visible;
  }

  .email_container_invisible {
    visibility: hidden;
  }

  .spinner_overlay {
    top: -250px;
  }

  .spinner_visible,
  .thank_you_visible {
    visibility: visible;
  }

  .spinner_visible,
  .thank_you_invisible {
    visibility: hidden;
  }

  .g-recaptcha {
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
