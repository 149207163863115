@use '~@unisporkal/alliance-ui-theme/dist/styles/iStock/_index.scss' as
  istockStyles;
@use '~@unisporkal/alliance-ui-theme/dist/styles/getty/_index.scss' as
  gettyStyles;
@use '~@unisporkal/alliance-ui-theme/dist/styles/common/_index.scss' as common;
@import 'unisporkal-styles/stylesheets/unisporkal/getty/config/_media_breakpoints';

.music_container {
  .main_body {
    padding: 50px 65px 65px;
  }

  .music-notice-container {
    max-width: 612px;
    margin: 0 auto;
    background: common.$true-white;
    padding: 40px;
    text-align: center;
    margin-top: 40px;
  }

  .music-notice-container a {
    text-decoration: none;
  }

  .music-notice-container__intro {
    font-size: 22px;
  }

  .music-notice-container__partner-logo {
    display: block;
    margin: 20px;

    &.triple_scoop svg {
      height: 100%;
    }

    path {
      fill: common.$true-black;
    }
  }

  .music-notice-container__partner-logo svg {
    width: 100%;
  }

  p.music-notice-container__text--english-only {
    font-size: 13px;
    margin-top: 40px;
  }

  .btn--primary {
    padding: 10px;
    max-width: 312px;
    margin: 10px auto;

    &:hover {
      cursor: pointer;
    }
  }

  .advanced-dropdown-field__box {
    max-width: 312px;
    margin: 30px auto 10px auto;
    padding: 0 0 0 13px;

    &.error {
      border-color: common.$crimson;
    }
  }

  .advanced-dropdown-field__label {
    text-align: left;
    font-size: 12px;
    font-size: 12px;
  }

  .dropdown-field {
    border: none;
    padding: 0.1em 0;
    background-position-y: top;
  }
}

.music_container.getty {
  .content_wrapper {
    background-image: url(~static_content/music/music_interstitial_background.png);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .btn--primary {
    background-color: gettyStyles.$primary;

    &:hover {
      background-color: gettyStyles.$primary-dark;
    }
  }

  .music-notice-container__text a {
    color: gettyStyles.$primary;
  }
}

.music_container.istock {
  .main_body {
    background-image: url(~static_content/music/music_interstitial_background.png);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .btn--primary {
    background-color: istockStyles.$secondary;

    &:hover {
      background-color: istockStyles.$secondary-dark;
    }
  }

  .music-notice-container__text a {
    color: istockStyles.$secondary;
  }
}

.btn--primary a {
  color: common.$true-white;
}
