.sitemap {
  .headline {
    clear: both;
    margin-left: 18%;

    .header {
      margin-left: 0;
    }
  }

  .category-list-container {
    border: none;
    width: 20.8%;
    min-height: 320px;
    padding: 10px;
    margin-top: 0;

    &.left {
      margin-right: 0.76%;
      margin-left: 18%;
      float: left;
      clear: left;
    }

    &.middle {
      margin-right: 0;
      float: left;
    }

    &.right {
      margin-left: 0.76%;
      margin-right: 18%;
      float: right;
      clear: right;
    }

    > div:first-child {
      border-bottom: 1px solid lightgrey;

      h4 {
        padding-left: 10px;
        padding-bottom: 0;
        font-size: 14px;
        font-weight: normal;
        line-height: 2;
      }
    }

    .featuredCategories {
      padding-left: 10px;

      li {
        line-height: 1.8;

        a {
          font-weight: 400;
          font-size: 14px;
        }
      }
    }
  }

  @media #{$small-only} {
    .headline {
      margin-left: 0;
    }

    .category-list-container {
      display: block;
      clear: both;
      width: 100%;
      text-align: left;
      margin: 0;

      &.left,
      &.right,
      &.middle {
        margin: 0;
        float: none;
      }
    }
  }

  @media #{$medium-up} {
    .main-content {
      max-width: 980px;
      margin: 0 auto;
    }
  }
}
