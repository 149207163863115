@media (width <= 350px) {
  body.customer_support_container {
    font-size: inherit;
  }
}

// two columns
@media (width >= 769px) {
  body.customer_support_container {
    .customer_support div.top_faqs .section-toggler {
      display: none;
      line-height: 25px;
    }

    .custom_notice {
      padding: 32px 150px;
      border-radius: 8px;
    }
  }
}

@media (width <= 767px) {
  body.customer_support_container .customer_support {
    div.top_faqs .faq-container {
      display: none;
    }

    div.top_faqs .faq-container.faq-container-changed {
      display: block;
    }

    .content_wrapper div.site-width .customer_support select {
      width: 100%;
      margin: 20px 0;
    }

    .custom_notice {
      padding: 32px;
      border-radius: 0;
    }
  }
}

// One column//
@media (width <= 768px) {
  body.customer_support_container .content_wrapper {
    .customer_support {
      width: 100%;
      max-width: 596px;

      div.content_creators {
        margin-bottom: 0;
        padding: 20px;
      }

      div.left {
        display: inherit;
        width: 100%;
        max-width: none;
        margin: 0;

        &.mobile-width-limited {
          max-width: 100%;
        }
      }

      div.right {
        display: inherit;
        max-width: none;
        width: 100%;
        padding: 0 20px;
        margin: 24px 0 0;
      }

      div.top_faqs {
        padding: 20px;
        margin: 20px 20px 0;

        div.faq-container {
          padding-right: 20px;
        }
      }

      div.how_to_reach_us {
        margin-top: 24px;
      }
    }
  }
}

@media screen and (max-width: $max_mobile_width) {
  body.customer_support_container .content_wrapper {
    div.how_to_reach_us {
      .xp_content {
        margin: 0;
      }

      div.icon {
        margin-left: -10px;
      }
    }
  }

  .customer_support div.top_faqs {
    h4 {
      width: 95%;
    }
  }
}

@media (width >= 501px) {
  div.phone_mobile {
    display: none;
  }

  div.content_creators_mobile {
    display: none;
  }

  div.phone_button {
    display: none;
  }

  div.phone_text {
    display: block;
  }

  div.phone_desktop {
    display: block;
  }

  div.chat_desktop {
    display: block;
  }

  div.content_creators {
    padding: 20px;
  }

  div.attribution_box {
    display: block;
  }
}

@media (width <= 500px) {
  .main_body {
    max-width: none;
  }

  body.customer_support_container
    .content_wrapper
    div.site-width
    .customer_support
    select {
    width: 100%;
    margin: 20px 0;
  }

  body.customer_support_container .content_wrapper {
    .customer_support {
      display: block;

      h4 {
        font-size: 14px;
      }

      div.left {
        margin-bottom: 0;
      }

      div.phone_mobile {
        display: block;
      }

      div.content_creators_mobile {
        display: block;
        padding: 20px;

        p.desktop {
          display: none;
        }

        margin-top: 0;

        h4 {
          margin-bottom: 5px;
        }

        p.mobile {
          margin-top: 0;
        }
      }

      div.phone_button {
        display: block;
      }

      div.phone_text {
        display: none;
      }

      div.phone_desktop {
        display: none;
      }

      div.chat_desktop {
        display: none;
      }

      div.attribution_box {
        display: none;
      }
    }
  }

  .mobileHr {
    margin: -20px 0 20px !important;
    width: 0;
  }
}

@media (width <= 370px) {
  body.customer_support_container {
    .content_wrapper {
      .customer_support {
        div.hours_text {
          p {
            font-size: 12px;
          }
        }

        div.phone_mobile {
          a.button {
            font-size: 12px;
          }
        }

        .customer_number_mobile {
          font-size: 12px;
        }
      }
    }
  }
}
