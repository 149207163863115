/**
 * FAQ section for Contact Us
 */

$faq-highlight-color: lighten($some-grey-border, 6%);
$faq-headline-color: #555;
$faq-headline-color-hover: #212121;

body.customer_support_container {
  .content_wrapper {
    .customer_support {
      .left {
        .top_faqs {
          @include clearfix;
          width: 100%;
          margin: 0 auto;
          padding: 0;

          h2 {
            font-size: rem(18);
            margin: 0 auto 25px;
            width: 85%;
          }

          h4 {
            margin-left: 2%;
            margin-right: 2%;
          }

          .qa-section {
            .question-answer-block {
              border-top: 1px solid $some-grey-border;
              border-bottom: 1px solid white;
              margin-bottom: 21px;
              text-align: left;
              font-size: rem(12);
              line-height: 1.4;
              width: 100%;

              div {
                display: none;
              }
              &.ui-accordion {
                display: block;
              }

              h3,
              div {
                border-radius: 0;
                transition: background-color 0.2s ease-in;
                background-color: transparent;
                border: none;
                outline: none;
              }

              h3 {
                position: relative;
                border-bottom: 1px solid $some-grey-border;
                border-top: 1px solid white;
                font-size: rem(14);
                margin-top: 0;
                padding: 25px 32px 25px 32px;
                color: $faq-headline-color;

                &.ui-state-active {
                  background-color: $faq-highlight-color;
                  border-color: transparent;
                  color: $faq-headline-color-hover;
                }

                &.ui-state-hover,
                &:hover {
                  color: $faq-headline-color-hover;
                }
              }

              div {
                padding: 0 32px 15px 32px;

                &.ui-accordion-content-active {
                  background-color: $faq-highlight-color;
                }

                p {
                  font-size: rem(12);
                  &:last-child {
                    margin-bottom: 0;
                  }
                }

                a {
                  color: $green;
                  &:hover {
                    color: darken($green, 5%);
                  }
                }
              }

              .ui-accordion-header {
                cursor: pointer;
              }

              .ui-accordion-header-icon {
                background-image: url('~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/icons/arrow-bullets-open.svg');
                background-repeat: no-repeat;
                background-size: contain;
                float: right;
                height: 20px;
                left: initial;
                right: 20px;
                transition: transform $fast-duration ease-in-out;
                width: 17px;

                &.selectedIcon {
                  @include transform(rotate(-180deg));
                }
              }
            }
          }

          @media #{$large-up} {
            h2 {
              font-size: rem(21);
              margin-bottom: 25px;
              text-align: left;
              margin-left: 24px;
              width: 100%;
            }

            .qa-section {
              .question-answer-block {
                width: 100%;
                font-size: rem(14);
                h3 {
                  font-size: rem(16);
                  font-weight: normal;
                  padding: 21px 24px;
                }

                div {
                  padding: 0 24px 20px;

                  p {
                    font-size: rem(14);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
