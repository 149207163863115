.tile-outer {
  margin-left: 1em;
  margin-right: 1rem;
  max-width: 1280px;

  @media only screen and (width >= 1292px) {
    margin-left: auto;
    margin-right: auto;
  }

  @media #{$tablet} {
    margin-left: 16px;
    margin-right: 16px;
  }

  @media #{$mobile} {
    margin-left: 16px;
    margin-right: 16px;
  }

  section.recent-searches + &,
  .search-box-placeholder + & {
    padding-top: 1.5em;
  }

  &:nth-of-type(2) {
    margin-top: 2rem;
  }

  &:last-of-type {
    margin-bottom: 4rem;
  }

  .tile-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;

    article.card {
      display: inline-block;
      margin: 0.4em;
      position: relative;
    }

    .card:hover .bg-image,
    .card:focus .bg-image {
      filter: brightness(0.7);
      transition-duration: 0.2s;
      transition-property: all;
    }

    .card:hover .file-id a,
    .card:focus .file-id a {
      display: block;
    }

    .bg-image {
      display: block;
      background-position: center center;
      background-size: cover;
      background-color: #9b9b9b;

      img {
        position: absolute;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    figure {
      margin: 0;
      height: 225px;
      width: 305px;
      position: relative;
    }

    figure figcaption {
      color: white;
      position: absolute;
      bottom: 0;
      padding: 0.5rem 1rem;
      width: 100%;
      background: linear-gradient(-180deg, rgb(0 0 0 / 0%) 0%, #000 150%);
    }

    .file-id a {
      display: none;
      text-decoration: none;
      text-shadow: 1px 1px 2px rgb(0 0 0 / 75%);
      color: white;
      margin: 0.5rem 1rem;
      position: absolute;
      top: 0;
      right: 0;
    }

    .file-id a:hover,
    .file-id a:focus {
      color: $green;
    }
  }

  .title-container {
    color: black;
    margin: 0 auto;
    text-align: center;
    width: 100%;

    h1 {
      text-align: center;
      margin: 37px 0 30px;
      font-size: rem(28);
      line-height: rem(40);
    }

    .description {
      font-size: rem(18);
      line-height: rem(28);
      margin: 0 auto;
      padding-bottom: rem(40);
    }
  }
}
