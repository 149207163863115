.editor-container {
  section.main_body {
    background-color: #f5f8fa;
  }

  #promo-code {
    background-color: #95d1c7;

    .promo-code-hero-container {
      background-position: bottom;
      background-repeat: no-repeat;
      background-size: 135%;

      .promo-code-container {
        padding-bottom: 60vw;

        .title-container {
          padding-top: 37px;

          .hero-title {
            font-size: 38px;
            float: none;
            width: auto;
            margin-top: 0;
            margin-bottom: 10px;
          }

          .hero-description {
            padding-bottom: 20px;
          }
        }

        .hero-details {
          top: 300px;
          left: 20vw;
          right: 20vw;
          bottom: 0;
          display: block;
          z-index: 5;

          a {
            display: inline-block;
            width: 100%;
            height: 100%;
          }
        }
      }

      .plans-and-pricing-cta {
        margin-top: 0;

        .plans-and-pricing-button {
          position: relative;
          z-index: 10;

          a {
            width: auto;
          }
        }
      }
    }
  }

  .featured {
    background-color: transparent;

    .header {
      p {
        font-size: 21px;
      }
    }

    .inside-box {
      .feature .copy {
        font-size: 14px;
      }
    }
  }

  .front-page-search-area {
    .hero-promo-container {
      .hero-container {
        text-align: center;

        .hero {
          display: none;
        }

        #heroPromoBanner {
          display: none;
        }

        #viewImageHoverArea {
          display: none;
        }
      }
    }
  }

  .fourpack-container {
    margin: 50px auto;
    text-align: center;
    max-width: 1000px;

    p {
      font-size: 21px;
    }

    .fourpack-tiles {
      width: 80%;
      margin: 10%;

      article {
        h3 {
          font-size: 18px;
          font-weight: 500;
        }

        p {
          font-size: 14px;
        }
      }
    }
  }

  div#promo-code:last-child .promo-code-hero-container {
    background-repeat: repeat;
    background-size: auto;
    height: 150px;

    .promo-code-container {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      height: 100%;
      padding-bottom: 0;

      .title-container {
        padding-top: 0;

        .hero-title {
          position: absolute;
          float: none;
          width: auto;
        }

        .hero-description {
          font-size: 1.5rem;
          font-weight: 500;
          padding: 0;
          margin: 0;
        }
      }

      .license {
        position: absolute;
      }

      .plans-and-pricing-cta {
        .plans-and-pricing-button {
          width: 180px;

          a {
            margin: 0;
          }
        }
      }
    }
  }
}

@media #{$medium-up} {
  // 641px
  .editor-container {
    #promo-code {
      background-color: transparent;

      .promo-code-hero-container {
        background-position: center top;
        background-size: auto;
        background-repeat: no-repeat;
        height: 895px;

        .promo-code-container {
          padding-bottom: 0;
        }

        .plans-and-pricing-cta {
          margin-top: 0;
        }
      }
    }

    .fourpack-container {
      position: relative;
      max-width: 1300px;

      h2 {
        background-image: url('/resources/images/Editor/editorscreen_text.png');
        background-position: left;
        background-repeat: no-repeat;
        background-size: 800px;
        position: absolute;
        right: 0;
        margin-left: 100px;
        height: 800px;
        width: 50%;
      }

      .fourpack-tiles {
        text-align: left;
        width: 35%;

        figure {
          float: left;

          img {
            height: 50px;
            width: 50px;
          }
        }

        article {
          div {
            margin-left: 70px;
          }
        }
      }
    }

    div#promo-code:last-child .promo-code-hero-container {
      .promo-code-container {
        flex-direction: row;
        justify-content: center;
        margin: 0 auto;
        padding: 0;

        .title-container {
          width: auto;
          margin: 0;
        }

        .plans-and-pricing-cta {
          width: auto;
          margin: 0;
          margin-left: 20px;
        }
      }
    }
  }

  // Locale-specific selectors for the image on the 4-pack-tile component.
  .de .editor-container .fourpack-container h2 {
    background-image: url('/resources/images/de_DE/Editor/editorscreen_text.png');
  }

  .es .editor-container .fourpack-container h2 {
    background-image: url('/resources/images/es_ES/Editor/editorscreen_text.png');
  }

  .fr .editor-container .fourpack-container h2 {
    background-image: url('/resources/images/fr_FR/Editor/editorscreen_text.png');
  }

  .it .editor-container .fourpack-container h2 {
    background-image: url('/resources/images/it_IT/Editor/editorscreen_text.png');
  }

  .nl .editor-container .fourpack-container h2 {
    background-image: url('/resources/images/nl_NL/Editor/editorscreen_text.png');
  }

  .ru .editor-container .fourpack-container h2 {
    background-image: url('/resources/images/ru_RU/Editor/editorscreen_text.png');
  }

  .sv .editor-container .fourpack-container h2 {
    background-image: url('/resources/images/sv_SE/Editor/editorscreen_text.png');
  }

  .tr .editor-container .fourpack-container h2 {
    background-image: url('/resources/images/tr_TR/Editor/editorscreen_text.png');
  }

  .ja .editor-container .fourpack-container h2 {
    background-image: url('/resources/images/ja_JP/Editor/editorscreen_text.png');
  }

  .ko .editor-container .fourpack-container h2 {
    background-image: url('/resources/images/ko_KR/Editor/editorscreen_text.png');
  }

  .pl .editor-container .fourpack-container h2 {
    background-image: url('/resources/images/pl_PL/Editor/editorscreen_text.png');
  }

  .pt-br .editor-container .fourpack-container h2 {
    background-image: url('/resources/images/pt_BR/Editor/editorscreen_text.png');
  }

  .pt-pt .editor-container .fourpack-container h2 {
    background-image: url('/resources/images/pt_PT/Editor/editorscreen_text.png');
  }

  .zh-hk .editor-container .fourpack-container h2 {
    background-image: url('/resources/images/zh_HK/Editor/editorscreen_text.png');
  }
}
